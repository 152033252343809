import React, { Component } from 'react';
import SecuredZone from '../components/SecuredZone';
import Card from '../components/Card';
import '../css/main.css';
import '../css/vectormap.css';
import API from '../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PanelDialog from '../components/dialogs/panel-dialog/panel-dialog';
import PanelFlowDialog from '../components/dialogs/panel-flow-dialog/panel-flow-dialog';
import Dialog from '@material-ui/core/Dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Map, Marker } from "pigeon-maps"
import { CreateButtonInherit } from '../components/small/Buttons';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
  ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
  Label, LabelList } from 'recharts';
import { CirclePicker } from 'react-color';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';

const AutodecApi = new API();

class PanelsOpen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panel: {
        id: '',
        unikey: '',
        created: '',
        spec: {
          name: "Loading..."
        },
        displayData: {
          color: '',
          enabled: '',
          brightness: 90,
          display: '',
        }
      },
      stats:[],
      dialogOpen: false,
      loading: true,
      chartTemperature: [],
      chartHumidity: [],
    };
  }

  handleClickOpen() {
    this.setState({ dialogOpen:  true });
  }

  handleClose() {
    this.setState({ dialogOpen:  false });
  }

  submitDialog(data) {
    this.setState({ dialogOpen:  false });

    this.updatePanelData();
  }

  handleFlowClickOpen(data, index) {
    this.setState({ flowEdit:  data });
    this.setState({ flowEditIndex:  index });
    this.setState({ dialogFlowOpen:  true });
  }

  updatePanelData()
  {
    AutodecApi.endpoints.panels.update(this.state.panel)
  }

  updatePanelColor(colors)
  {
    this.state.panel.displayData.color = colors;
    this.setState({ loading: false });

    AutodecApi.endpoints.panels.update(this.state.panel)
  }

  updatePanelBrightness(brightness)
  {
    this.state.panel.displayData.brightness = brightness;
    this.setState({ loading: false });

    AutodecApi.endpoints.panels.update(this.state.panel)
  }

  statusChange(data)
  {
    this.state.panel.displayData.enabled = data;
    this.setState({ loading: false });

    AutodecApi.endpoints.panels.update(this.state.panel)
  }

  handleClickDelete()
  {
    AutodecApi.endpoints.panels.delete(this.state.panel);
    const timeout = setTimeout(() => {
      this.props.history.push('/panels')
    }, 1000);
  }

  componentDidMount() {
    const AutodecApi = new API();
    AutodecApi.endpoints.panels.getOne({ id: this.props.match.params.id })
      .then((panelGet) => {
        if(panelGet)
        {
          this.setState({ panel:  panelGet.data });
          this.setState({ loading: false });
          this.setState({ loadingFlows: false });
        }
      });

    AutodecApi.endpoints.panelsRequestList.getOne({ id: this.props.match.params.id })
      .then((panelGet) => {
        if(panelGet)
        {
          var statsData = [];

          for (var i = 0; i < panelGet.data.length; i++)
          {

            var currentStat = {};
            currentStat.date = (new Date(Date.parse(panelGet.data[i].created)).getHours()+":"+new Date(Date.parse(panelGet.data[i].created)).getMinutes());
            currentStat.data = (parseFloat(panelGet.data[i].temperature));
            statsData.push(currentStat)
          }

          var statsDataReversed =  [...statsData].reverse();

          this.setState({ chartTemperature: statsDataReversed });


          //humidity

          var statsData = [];

          for (var i = 0; i < panelGet.data.length; i++)
          {

            var currentStat = {};
            currentStat.date = (new Date(Date.parse(panelGet.data[i].created)).getHours()+":"+new Date(Date.parse(panelGet.data[i].created)).getMinutes());
            currentStat.data = (parseFloat(panelGet.data[i].humidity));
            statsData.push(currentStat)
          }

          var statsDataReversed =  [...statsData].reverse();

          this.setState({ chartHumidity: statsDataReversed });
        }
      });


  }

  render() {
    return (
      <SecuredZone title="Panels"
        breadcrumbs={{ '/': 'Dashboard', '/panels':'Panels', [`/panels/${this.props.match.params.id}`] : `Panel - ${this.state.panel.spec.name}` }}
        menu={{ 'Löschen': this.handleClickDelete.bind(this),'Bearbeiten': this.handleClickOpen.bind(this) }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose.bind(this)}>
          <PanelDialog panelData={this.state.panel} close={this.handleClose.bind(this)} submit={this.submitDialog.bind(this)}/>
        </Dialog>

        <Card width="2-5" title="Name" data=<DisplayCardName name={this.state.panel.spec.name}/>/>
        { this.state.loading ?
          <span></span>
          :
          <Card width="1-5" title="Heizung" data=<DisplayCardRelais relaisData={this.state.panel.displayData.relais} />/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="2-5" title="Standort" data=<DisplayCardLocation location={this.state.panel.spec.location} displayData={this.state.panel.displayData} name={this.state.panel.spec.name}/>/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="1-3" title="Einstellungen" data=<DisplayCardSettings updatePanelColor={this.updatePanelColor.bind(this)} updatePanelBrightness={this.updatePanelBrightness.bind(this)} displayData={this.state.panel.displayData} />/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="1-3" title="Aktuelle Anzeige" data=<DisplayCardCurrent data={this.state.panel.displayData}/>/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="1-3" title="Status" data=<DisplayCardStatus statusChange={this.statusChange.bind(this)} data={this.state.panel.displayData}/>/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="2" title="Temperatur" data=<DisplayCardChartsTemperature chartdat={this.state.chartTemperature}/>/>
        }
        { this.state.loading ?
          <span></span>
          :
          <Card width="2" title="Feuchtigkeit" data=<DisplayCardChartsHumidity chartdat={this.state.chartHumidity}/>/>
        }

        { this.state.loading ?
          <span></span>
          :
          <Card width="1" title="Update URL" data=<DisplayUpdateUrls unikey={this.state.panel.unikey}/>/>
        }
      </SecuredZone>
    );
  }
}

export function DisplayCardRelais(props) {
  return (
    <div className="current-display-big">
      {(props.relaisData ? "Ein" : "Aus")}
    </div>
  );
}

export function DisplayCardName(props) {
  return (
    <div className="current-display-big">
      {props.name}
    </div>
  );
}

export function DisplayUpdateUrls(props) {
  return (
    <div className="current-display-url-big">
      https://api.autodec.ch/v1/panels-update/{props.unikey}/?number
    </div>
  );
}

export function DisplayCardStatus(props) {
  return (
    <>
      <div style={{height:"240px"}} className="current-display-status">
        {(props.data.enabled ? 'Aktiviert' : 'Deaktiviert')}
      </div>
      <div className="current-display-status-switch">
        <Switch checked={props.data.enabled} onChange={(event,data) => { props.statusChange(data) }} color="warning"/>
      </div>
    </>
  );
}

export function DisplayCardCurrent(props) {
  return (
    <div style={{color: props.data.color.hex,height:"290px"}} className="current-display-current">
      {props.data.display}
    </div>
  );
}

export function DisplayCardChartsHumidity(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="%">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartHumidity />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

export function DisplayCardChartsTemperature(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="°C">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartTemperature />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

const CustomTooltipChartTemperature = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} °C</div>
      </div>
    );
  }

  return null;
};

const CustomTooltipChartHumidity = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} %</div>
      </div>
    );
  }

  return null;
};

class DisplayCardSettings extends Component {
  constructor(props)
  {
    super(props);

    this.state = props.displayData;
  }

  render() {
    return (
      <div style={{maxWidth:'360px', height:'270px', margin:'auto', marginTop:'67px'}}>
        <div className="current-display-settings">
          <div className="current-display-settings-title">Farbe</div>
          <div className="current-display-settings-color">
            <CirclePicker className={this.state.color.hex === '#ffffff' ? 'white-selected' : ''} onChangeComplete={(event) => { this.props.updatePanelColor(event) }} value={this.state.color.hex} color={this.state.color.hex} colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#fff"]}>
            </CirclePicker>
          </div>
        </div>
        <div style={{height:'50px'}} className="current-display-settings">
          <div className="current-display-settings-title">Helligkeit</div>
          <div className="current-display-settings-slider">
            <Slider value={this.state.brightness} defaultValue={60}
                    step={5}
                    marks
                    min={10}
                    max={255}
                    onChangeCommitted={(event,data) => { this.props.updatePanelBrightness(data) }}
              />
          </div>
        </div>
      </div>
    );
  }
}


class DisplayCardLocation extends Component {
  render() {
    return (
      <div className="map-display">
          <Map style={{height:'100%'}} defaultCenter={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} defaultZoom={15}>
            <Marker width={30} anchor={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} /*onClick={() => setHue(hue + 20)} */>
             <div style={{color: this.props.displayData.color.hex}} class="panel-display-box">
              {(this.props.displayData.enabled ? this.props.displayData.display : "")}
             </div>
            </Marker>
          </Map>
      </div>
    );
  }
}


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default PanelsOpen;
