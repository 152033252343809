import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './calendar-event-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import dayjs from "dayjs";

const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class CalendarEventDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      calendarEvent: {
        unikey: '',
        customer: '',
        car: '',
        end: '',
        start: ''
      },
      cars:[],
      customers:[],
      loading: false,
      isAddMode: true,
    };

    if(props.toData)
    {
      if(props.toData.create == true)
      {
        this.state.isAddMode = true;
        this.state.calendarEvent.start = props.toData.start;
        this.state.calendarEvent.end = props.toData.end;
        this.state.calendarEvent.unikey = uuid();
      }
      else
      {
        this.state.isAddMode = false;
        this.state.calendarEvent.start = props.toData.start;
        this.state.calendarEvent.end = props.toData.end;
        this.state.calendarEvent.id = props.toData.id;
        this.state.calendarEvent.unikey = props.toData.unikey;
        this.state.calendarEvent.car = props.toData.ressource;
        console.log(props.toData);
      }
    }
    else
    {
      this.state.calendarEvent.unikey = uuid();
    }
  }

  componentDidMount()
  {
    this.getCars();
    this.getCustomers();
  }

  getCustomers()
  {
    this.setState({ loading: true });
    AutodecApi.endpoints.customers.getAll()
      .then((rentalGet) => {
        try
        {
          this.setState({ customers:  rentalGet.data.results });
          this.state.calendarEvent.customer = rentalGet.data.results[0].id;
          this.setState({ loading: false });
        }
        catch(e)
        {

        }
      });
  }

  getCars()
  {
    this.setState({ loading: true });
    AutodecApi.endpoints.cars.getAll()
      .then((rentalGet) => {
        try
        {
          this.setState({ cars:  rentalGet.data.results });
          if(!this.state.calendarEvent.car)
          {
            this.state.calendarEvent.car = rentalGet.data.results[0].id;
          }
          this.setState({ loading: false });
        }
        catch(e)
        {

        }
      });
  }

  render() {
    return (
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          <DialogTitle id="form-dialog-title">{ this.state.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
          { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
          <DialogContent>
            <div className="pt-line-two">
              <FormControl>
                <InputLabel htmlFor="demo-customized-select-native">Fahrzeug</InputLabel>
                <NativeSelect
                  disableAutoFocusItem
                  id="panel"
                  label="Fahrzeug"
                  value={this.state.calendarEvent.car}
                  onChange={linkState(this, 'calendarEvent.car')}
                  input={<BootstrapInput />}
                >
                  {this.state.cars.map((row) => (
                      <option key={row.id} value={row.id}>{row.make} {row.model} / {row.plate}</option>
                    )
                  )}
                </NativeSelect>
              </FormControl>
            </div>
            <div className="pt-line-two">
              <FormControl>
                <InputLabel htmlFor="demo-customized-select-native">Kunde</InputLabel>
                <NativeSelect
                  disableAutoFocusItem
                  id="panel"
                  label="Kunde"
                  value={this.state.calendarEvent.customer}
                  onChange={linkState(this, 'calendarEvent.customer')}
                  input={<BootstrapInput />}
                >
                  {this.state.customers.map((row) => (
                      <option key={row.id} value={row.id}>{row.firstName} {row.secondName}</option>
                    )
                  )}
                </NativeSelect>
              </FormControl>
            </div>
            <div style={{marginTop: '20px'}} className="pt-line-one">
              <DateTimePicker
                autoFocus
                adapterLocale="de"
                margin="dense"
                id="make"
                label="Start Datum"
                type="text"
                fullWidth
                variant="outlined"
                value={dayjs(this.state.calendarEvent.start)}
                onChange={linkState(this, 'calendarEvent.start')}
              />
              <DateTimePicker
                autoFocus
                adapterLocale="de"
                margin="dense"
                id="make"
                label="End Datum"
                type="text"
                fullWidth
                variant="outlined"
                value={dayjs(this.state.calendarEvent.end)}
                onChange={linkState(this, 'calendarEvent.end')}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="p-line-end">
              <Button variant="contained" onClick={this.props.close}>
                Abbrechen
              </Button>
              <span></span>
              <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
                Speichern
              </Button>
            </div>
          </DialogActions>
        </LocalizationProvider>
      </div>
    );
  }
}

export default CalendarEventDialog;
