import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import linkState from 'linkstate';
import './panel-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';

class PanelDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      panel: {
        unikey: '',
        spec: {
          name: '',
          location:
          {
            lat: '',
            long: '',
          }
        },
        displayData: {
          color: '',
          enabled: '',
          relais: '',
          brightness: 90,
          display: '',
        }
      },
      loading: true,
    };

    if(props.panelData)
    {
      this.isAddMode = false;
      this.state.panel = props.panelData;
    }
    else
    {
      this.state.panel.unikey = uuid();
    }
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Panel { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="p-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.panel.spec.name}
              onInput={linkState(this, 'panel.spec.name')}
            />
          </div>
          <div className="p-line-two">
            <TextField
              margin="dense"
              id="location.lat"
              label="Lat"
              type="location.lat"
              fullWidth
              variant="outlined"
              value={this.state.panel.spec.location.lat}
              onInput={linkState(this, 'panel.spec.location.lat')}
            />
            <TextField
              margin="dense"
              id="location.long"
              label="Long"
              type="location.long"
              fullWidth
              variant="outlined"
              value={this.state.panel.spec.location.long}
              onInput={linkState(this, 'panel.spec.location.long')}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default PanelDialog;
