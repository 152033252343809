import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CustomerDialog from '../../components/dialogs/customer-dialog/customer-dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";

const AutodecApi = new API();

class Customers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogCustomer: false,
      customers: {
        unikey:'',
        token:'',
        name:'',
        create:'',
        active:'',
        displayData: [],
      }
    };
  }

  componentDidMount() {
    this.getCustomers();
  }

  getCustomers()
  {
    AutodecApi.endpoints.customers.getAll()
      .then((customersGet) => {
        this.setState({ customers:  customersGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeCustomer()
  {
    this.setState({ dialogCustomer:  false });
  }

  openCustomer() {
    this.setState({ dialogCustomer:  true });
  }

/*  deletePanelToken(panelToken) {
    console.log(panelToken);
    this.setState({ loading: true });
    AutodecApi.endpoints.panelsToken.delete( { id: panelToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getCustomers();
      });
  }*/

  submitCustomer(data)
  {
    this.closeCustomer();
    this.setState({ loading: true });
    if(data.isAddMode)
    {
      this.state.customers.push(data.customer)
    }

    AutodecApi.endpoints.customers.create(data.customer)
      .then(() => {
        this.setState({ loading: false });
        this.getCustomers();
      });
  }

  render() {
    return (
      <SecuredZone title="Kunden"
        breadcrumbs={{ '/': 'Dashboard', '/customers':'Kunden' }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Kunde Hinzufügen': this.openCustomer.bind(this)}}
      >
        <Dialog open={this.state.dialogCustomer} onClose={this.closeCustomer.bind(this)}>
          <CustomerDialog close={this.closeCustomer.bind(this)} submit={this.submitCustomer.bind(this)}/>
        </Dialog>
        <TableContainer className="settings-table" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Telefon</TableCell>
                <TableCell align="left">E-Mail Adresse</TableCell>
                <TableCell align="left">Adresse</TableCell>
                <TableCell align="left">Erstellt</TableCell>
                <TableCell width={60} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Array.isArray(this.state.customers) ?
                <>
                  {this.state.customers.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.firstName} {row.secondName}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.road}, {row.location}</TableCell>
                      <TableCell align="left">{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                      <TableCell size="small">
                        <div className="table-buttons">
                          <IconButton component={Link} to={`customers/${row.id}`}  size="small">
                            <OpenInNewIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
                :
                <>
                </>
              }

            </TableBody>
          </Table>
        </TableContainer>
      </SecuredZone>
    );
  }
}

export default Customers;
