import React from 'react';
import SecuredZone from '../components/SecuredZone';
import API from '../api/api';
import Card from '../components/Card';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import { Map, Marker } from "pigeon-maps"
import moment from 'moment-timezone';
import { Link } from "react-router-dom";

const AutodecApi = new API();


class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      interval: '',
      loading: true,
      loadingLocations: true,
      auth: {
        username: '',
        first_name: '',
        last_name: '',
        pk:'',
      },
      panels: [{
        panel: {
          id: '',
          unikey: '',
          created: '',
          spec: {},
        },
      }],
      locations:{},
    };
  }

  componentDidMount() {
    AutodecApi.endpoints.me.getAll()
      .then((userGet) => {
        this.setState({ auth:  userGet });
        this.setState({ loading: false });
      });

      /*AutodecApi.endpoints.panels.getAll()
        .then((panelGet) => {
          this.setState({ panels:  panelGet.data.results });

          var locationsArray = [];

          panelGet.data.results.map(function(d)
          {
            var thisloc = {};
            thisloc.latLng = [d.spec.location.lat, d.spec.location.long];
            thisloc.name = d.spec.name;
            thisloc.color = d.displayData.color.hex;
            thisloc.display = d.displayData.display;
            thisloc.enabled = d.displayData.enabled;
            locationsArray.push(thisloc);
            return true;
          });

          console.log(locationsArray);

          this.setState({ locations: locationsArray });
          this.setState({ loadingLocations: false });
        });

      this.interval = setInterval(() => this.setState(
        AutodecApi.endpoints.panels.getAll()
          .then((panelGet) => {
            this.setState({ panels:  panelGet.data.results });

            var locationsArray = [];

            panelGet.data.results.map(function(d)
            {
              var thisloc = {};
              thisloc.latLng = [d.spec.location.lat, d.spec.location.long];
              thisloc.name = d.spec.name;
              thisloc.color = d.displayData.color.hex;
              thisloc.display = d.displayData.display;
              thisloc.enabled = d.displayData.enabled;
              locationsArray.push(thisloc);
              return true;
            });

            console.log(locationsArray);

            this.setState({ locations: locationsArray });
            this.setState({ loadingLocations: false });
          })
      ), 5000);*/
  }

  componentWillUnmount() {
  clearInterval(this.interval);
}


  render() {
    return (
      <SecuredZone breadcrumbs={{ '/': 'Dashboard' }} title="Dashboard" loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }>
        { this.state.loadingLocations ?
          <span></span>
          :
          <Card width="1" title="Panel Standorte" data=<DisplayCardLocation locations={this.state.locations}/>/>
        }
        { this.state.loadingLocations ?
          <span></span>
          :
          <Card width="1" title="Panel Status" data=<DisplayDiagramStatus panels={this.state.panels}/>/>
        }
      </SecuredZone>
    );
  }
}

class DisplayCardLocation extends Component {

  render() {
    return (
      <div className="map-display-dashboard">
          <Map style={{height:'100%'}} defaultCenter={[46.495349,9.839747]} defaultZoom={13}>

            {this.props.locations.map(function(loc)
              {


                if(parseInt(loc.latLng[0]) && parseInt(loc.latLng[1]))
                {
                  return (
                    <Marker width={30} anchor={[parseFloat(loc.latLng[0]), parseFloat(loc.latLng[1])]} /*onClick={() => setHue(hue + 20)} */>
                     <div style={{color: loc.color}} class="panel-display-box">
                      {(loc.enabled ? loc.display : "")}
                     </div>
                    </Marker>
                  );
                }

              })
            }
          </Map>
      </div>
    );
  }
}


class DisplayDiagramStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount()
  {
    this.getRequests();
  }

  getRequests() {
    const promises = [];
    this.props.panels.forEach((panel) => {
        promises.push(AutodecApi.endpoints.panelsRequest.getOne({ id : panel.id }));
    })

    Promise.all(promises).then((responses) => {
        const data = responses.map((response) => response.data);
        this.setState({data});
    })
  }

  render() {
    return (
      <div>
      {
        this.state.data ?
          this.state.data.map((request, index) => {

            var panelData = this.props.panels[index];
            var lastRequest = 0;
            var lastRequestCount = 0;
            var currentTime =  new Date( Date.now() - 60000 * 30 ).getTime();

            try {
              if(Object.keys(request !== 0))
              {
                lastRequestCount = new Date(request[0].created).getTime();
                lastRequest = moment(new Date(request[0].created)).format('DD.MM.YYYY HH:mm');
              }
            } catch (e)
            {
              lastRequest = 0
            }


            const color = ((lastRequestCount > currentTime) ? '#ABFF00' : '#F00')
            const shadow = ((lastRequestCount > currentTime) ? 'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89FF00 0 2px 12px' : 'rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0')

            return (
              <div key={panelData.id} className="dashboard-display-status">
                <div style={{ backgroundColor: color, boxShadow: shadow }} className="dashboard-display-status-color"></div>
                <div className="dashboard-display-status-name">{panelData.spec.name}</div>
                <div className="dashboard-display-status-time">{lastRequest}</div>
              </div>
            )
          })
        :
        <></>
      }

      </div>
    );
  };
}

export default Dashboard;
