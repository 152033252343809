import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CalendarEventDialog from '../../components/dialogs/calendar-event-dialog/calendar-event-dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import esLocale from '@fullcalendar/core/locales/de';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import interactionPlugin from '@fullcalendar/interaction';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const AutodecApi = new API();

class CalendarEvent extends Component {
  constructor(props) {

    super(props);

    this.calendarRef = React.createRef()

    this.state = {
      loading: true,
      dialogCalendarEvent: false,
      cars: [],
      events: [],
      currentDateStart: '',
      currentDateEnd: '',
      currentDate: dayjs((new Date())),
      createData: {
        start: null,
        end: null,
        ressource: 0,
        create:false,
      }
    };
  }

  componentDidMount() {
    this.getCars();
    this.getCalendarEvent();
  }

  getCars()
  {
    AutodecApi.endpoints.carsView.getAll()
      .then((carsGet) => {

        var carsList = [];

        for (var i = 0; i < carsGet.data.results.length; i++)
        {
          var carData = {};

          carData.id = carsGet.data.results[i].id;
          carData.title =  carsGet.data.results[i].make + " " +  carsGet.data.results[i].model + " ("+  carsGet.data.results[i].plate + ")"

          carsList.push(carData);
        }
        this.setState({ cars:  carsList });
        this.setState({ loading: false });
      });
  }

  selectedDate(event)
  {
    this.state.createData.start = new Date(moment(event.start).format('YYYY-MM-DD HH:mm:ss'));
    this.state.createData.end = new Date(moment(event.end).format('YYYY-MM-DD HH:mm:ss'));
    this.state.createData.ressource = event.resource._resource.id;
    this.state.createData.create = true;
    this.setState({ dialogCalendarEvent:  true });
  }

  closeCalendarEvent()
  {
    this.setState({ dialogCalendarEvent:  false });
  }

  openCalendarEvent()
  {
    this.setState({ dialogCalendarEvent:  true });
  }

  editDate(data)
  {
    var event = data.event;
    this.state.createData.start = new Date(moment(event.start).format('YYYY-MM-DD HH:mm:ss'));
    this.state.createData.end = new Date(moment(event.end).format('YYYY-MM-DD HH:mm:ss'));
    this.state.createData.id = event.id;
    this.state.createData.unikey = event.extendedProps.unikey;
    this.state.createData.ressource = event._def.resourceIds[0];
    this.state.createData.create = false;
    this.setState({ dialogCalendarEvent:  true });
  }

/*  deletePanelToken(panelToken) {
    console.log(panelToken);
    this.setState({ loading: true });
    AutodecApi.endpoints.panelsToken.delete( { id: panelToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getCalendarEvent();
      });
  }*/

  getCalendarEvent()
  {
    let calendarApi = this.calendarRef.current.getApi()

    var startDate = moment(calendarApi.currentData.dateProfile.activeRange.start).format('YYYY-MM-DD HH:mm:ss');
    var endDate = moment(calendarApi.currentData.dateProfile.activeRange.end).format('YYYY-MM-DD HH:mm:ss');

    AutodecApi.endpoints.calendarEventFilter.getAllDate({ start: startDate, end: endDate })
      .then((eventGet) => {

        var eventList = [];

        for (var i = 0; i < eventGet.data.results.length; i++)
        {
          var eventData = {};

          eventData.id = eventGet.data.results[i].id;
          eventData.title = eventGet.data.results[i].customer.firstName + " " +  eventGet.data.results[i].customer.secondName;
          eventData.start =  eventGet.data.results[i].start;
          eventData.end =  eventGet.data.results[i].end; // moment().format('MMMM Do YYYY, h:mm:ss a'); // April 23rd 2023, 9:45:09 pm
          eventData.resourceId = eventGet.data.results[i].car.id;
          eventData.unikey = eventGet.data.results[i].unikey;

          eventList.push(eventData);
        }

        this.setState({ events:  eventList });
        this.setState({ loading: false });

      });
  }

  updateEvent(data)
  {
    AutodecApi.endpoints.calendarEvent.patch({ id: data.event.id}, { start: data.event.start, end: data.event.end })
  }

  updateCalendarDate(date)
  {
    this.setState({ currentDate: dayjs(date)});
    this.calendarRef.current.getApi().gotoDate((new Date(moment(date).format('YYYY-MM-DD HH:mm:ss'))));
    this.getCalendarEvent();
  }

  submitCalendarEvent(data)
  {

    this.closeCalendarEvent();
    this.setState({ loading: true });

    data.calendarEvent.end = moment(data.calendarEvent.end).format('YYYY-MM-DD HH:mm:ss');
    data.calendarEvent.start = moment(data.calendarEvent.start).format('YYYY-MM-DD HH:mm:ss');

    if(data.isAddMode)
    {
      AutodecApi.endpoints.calendarEvent.create(data.calendarEvent)
        .then(() => {
          this.setState({ loading: false });
          this.getCalendarEvent();
        });
    }
    else
    {
      AutodecApi.endpoints.calendarEvent.update(data.calendarEvent)
        .then(() => {
          this.setState({ loading: false });
          this.getCalendarEvent();
        });
    }
  }

  render() {
    return (
      <SecuredZone title="Kalender"
        breadcrumbs={{ '/': 'Dashboard', '/calendar':'Kalender' }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Kalender Hinzufügen': this.openCalendarEvent.bind(this)}}
      >
        <Dialog open={this.state.dialogCalendarEvent} onClose={this.closeCalendarEvent.bind(this)}>
          <CalendarEventDialog toData={this.state.createData} close={this.closeCalendarEvent.bind(this)} submit={this.submitCalendarEvent.bind(this)}/>
        </Dialog>

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">

          <div className="calendar-holder">
            <FullCalendar
              ref={this.calendarRef}
              headerToolbar={false}
              locale={esLocale}
              editable={true}
              resourceAreaWidth={'370px'}
              selectable={true}
              height={'100%'}
              eventResourceEditable={true}
              events={this.state.events}
              select={(event) => this.selectedDate(event)}
              eventClick={(event) => this.editDate(event)}
              eventChange={(event) => this.updateEvent(event)}
              schedulerLicenseKey='CC-Attribution-NonCommercial-NoDerivatives'
              plugins={[ resourceTimelinePlugin, interactionPlugin ]}
              slotDuration={'12:00:00'}
              nowIndicator={true}
              resources={this.state.cars}
              initialView={'resourceTimelineWeek'}
              />
            </div>

            <div className="calendar-mover">
              <DateCalendar defaultValue={dayjs(new Date())} value={this.state.currentDate} onChange={(newValue) => this.updateCalendarDate(newValue)}/>
            </div>

          </LocalizationProvider>

      </SecuredZone>
    );
  }
}

export default CalendarEvent;
