export default function useAuth() {
  var token = '';
  var isAuth = false;

  if (localStorage.getItem('token') !== null)
  {
    isAuth = true;
    token = localStorage.getItem('token');
  }

  return {
    token: token,
    isAuth: isAuth,
  }
}
