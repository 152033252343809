import React from 'react';
//import { Link } from "react-router-dom";
import '../../css/titlebar.css';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';

export default function TitleBar(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div className="title-bar">
      <div className="title-bar-name">
        {props.text}
      </div>
      { props.menu ?

        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            onClick={handleClose}
          >
          { Object.entries(props.menu).map((item, index) =>
            {
              return (
                <MenuItem key={index} onClick={item[1]}>{item[0]}</MenuItem>
              );
            })
          }
          </Menu>
        </>
         :
         <span></span>
       }

    </div>
  );
}
