import React, { useEffect, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { focusHandling } from 'cruip-js-toolkit';
// Import pages
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings/Settings';
import SettingsControllerToken from './pages/Settings/ControllerToken';
import SettingsRentalLeases from './pages/Settings/RentalLeases';
import SettingsCompanyData from './pages/Settings/CompanyData';
import Customers from './pages/Customer/Customers';
import CustomerOpen from './pages/Customer/CustomerOpen';
import Rentals from './pages/Rental/Rentals';
import RentalOpen from './pages/Rental/RentalOpen';
import Calendar from './pages/Calendar/Calendar';
import Cars from './pages/Car/Cars';
import CarOpen from './pages/Car/CarOpen';
import PanelsOpen from './pages/PanelsOpen';
import AuthLogin from './pages/auth/Login';
import AuthLogout from './pages/auth/Logout';
import AuthSignup from './pages/auth/Signup';
import useAuth from './pages/auth/Auth';


function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]);

  return (
    <>
      <Switch>
        <PrivateRoute exact path="/" component={Dashboard}/>
        <PrivateRoute exact path="/panels/:id" component={PanelsOpen}/>

        <PrivateRoute exact path="/customers" component={Customers}/>
        <PrivateRoute exact path="/customers/:id" component={CustomerOpen}/>

        <PrivateRoute exact path="/calendar" component={Calendar}/>

        <PrivateRoute exact path="/cars" component={Cars}/>
        <PrivateRoute exact path="/cars/:id" component={CarOpen}/>

        <PrivateRoute exact path="/rentals" component={Rentals}/>
        <PrivateRoute exact path="/rentals/:id" component={RentalOpen}/>

        <PrivateRoute exact path="/settings" component={Settings}/>
        <PrivateRoute exact path="/settings/controller-token" component={SettingsControllerToken}/>
        <PrivateRoute exact path="/settings/rental-leases" component={SettingsRentalLeases}/>
        <PrivateRoute exact path="/settings/company-data" component={SettingsCompanyData}/>

        <PrivateRoute exact path="/auth/logout" component={AuthLogout}/>

        <PublicRoute path="/auth/login">
          <AuthLogin/>
        </PublicRoute>
        <PublicRoute path="/auth/register">
          <AuthSignup/>
        </PublicRoute>

        <ErrorRoute path="*"/>
      </Switch>
    </>
  );
}

function PrivateRoute({ ...rest })
{
  const auth = useAuth()
  return (
    <>
      {
        auth.isAuth ?
        (<Route {...rest}/>)
        :
        (<Redirect to="/auth/login" />)
      }
    </>
  )
}

function PublicRoute({ children, ...rest })
{
  const auth = useAuth()
  return (
    <Route {...rest} render={({ location }) =>
        auth.isAuth ?
        (<Redirect to="/" />)
        :
        (children)
      }
    />
  )
}

function ErrorRoute({ children, ...rest })
{
  const auth = useAuth()
  return (
    <Route {...rest} render={({ location }) =>
        auth.isAuth ?
        (<Redirect to="/" />)
        :
        (<Redirect to="/auth/login" />)
      }
    />
  )
}

export default App;
