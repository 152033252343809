import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './car-token-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';


const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class CarTokenDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      carToken: {
        unikey: '',
        name: '',
        enabled: true,
        token: '',
        car: 0,
      },
      cars:[],
      loading: true,
    };

    if(props.carToken)
    {
      this.isAddMode = false;
      this.state.carToken = props.carToken;
    }
    else
    {
      this.state.carToken.unikey = uuid();
    }
  }

  componentDidMount() {
    this.getCars();
  }

  getCars()
  {
    this.setState({ loading: true });
    AutodecApi.endpoints.cars.getAll()
      .then((carTokenGet) => {
        try
        {
          this.setState({ cars:  carTokenGet.data.results });
          this.setState({ loading: false });
          this.state.carToken.car = carTokenGet.data.results[0].id;
        }
        catch(e)
        {

        }
      });
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Autodec Controller { this.isAddMode ? "Hinzufügen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.carToken.name}
              onInput={linkState(this, 'carToken.name')}
            />
          </div>
          <div className="pt-line-two">
            <FormControl>
              <InputLabel htmlFor="demo-customized-select-native">Panel</InputLabel>
              <NativeSelect
                disableAutoFocusItem
                id="panel"
                label="Fahrzeug"
                value={this.state.carToken.car}
                onChange={linkState(this, 'carToken.car')}
                input={<BootstrapInput />}
              >
                {this.state.cars.map((row) => (
                    <option key={row.id} value={row.id}>{row.make} {row.model} / {row.plate}</option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </div>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Token des Controllers"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.carToken.token}
              onInput={linkState(this, 'carToken.token')}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default CarTokenDialog;
