import React, { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Card from '../../components/Card';
import '../../css/main.css';
import '../../css/vectormap.css';
import {v4 as uuid} from "uuid";
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RentalDialog from '../../components/dialogs/rental-dialog/rental-dialog';
import Dialog from '@material-ui/core/Dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import TextField from '@material-ui/core/TextField';
import { Map, Marker, GeoJson } from "pigeon-maps"
import { CreateButtonInherit } from '../../components/small/Buttons';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
  ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
  Label, LabelList } from 'recharts';
import { CirclePicker } from 'react-color';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import RegDummy from '../../images/reg_dummy.png';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import linkState from 'linkstate';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import dayjs from "dayjs";
import NativeSelect from '@mui/material/NativeSelect';
import SignatureCanvas from 'react-signature-canvas'

const AutodecApi = new API();

class RentalOpen extends Component {
  constructor(props) {
    super(props);

    this.sigCanvas = React.createRef();
    this.sigCanvasWorker = React.createRef();
    this.rentalLeaseViewer = React.createRef();

    this.state = {
      interval: '',
      stats:[],
      dialogOpen: false,
      loading: true,
      rentalLeasePDF: {
        name: 0,
        filePath: 0,
        rentalLease: 0,
      },
      rentalLeaseReceiptPDF: {
        name: 0,
        filePath: 0,
        rentalLease: 0,
      },
    	rental: {
    		id: 1,
    		unikey: '',
    		created: '',
    		status: 1,
    		updated: '',
    		enabled: '',
    		start: '',
    		end: '',
        priceCalc: '',
        priceEnd: '',
        discount: '',
        rentalLease: '',
    		signature: '',
    		frontImage: '',
    		frontImageLeft: '',
    		frontImageRight: '',
    		backImage: '',
    		backImageRight: '',
    		backImageLeft: '',
    		sideImageLeft: '',
    		sideImageRight: '',
    		car: {
    			make: 't',
    			model: '',
    		},
    		customer: {
    			firstName: '',
    			secondName: '',
    		},
    		user: ''
    	},
      route:{
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: []
            }
          },
        ],
      },
      rentalLeases: [],
      rentalView: {
    		id: 1,
    		unikey: '',
    		created: '',
    		status: 1,
    		updated: '',
    		enabled: '',
    		start: '',
    		end: '',
    		signature: '',
    		frontImage: '',
    		frontImageLeft: '',
    		frontImageRight: '',
    		backImage: '',
    		backImageRight: '',
    		backImageLeft: '',
    		sideImageLeft: '',
    		sideImageRight: '',
    		car: {
    			make: '',
    			model: '',
    		},
    		customer: {
    			firstName: '',
    			secondName: '',
    		},
    		user: ''
    	}
    };
  }

  handleClickOpen() {
    this.setState({ dialogOpen:  true });
  }

  handleClose() {
    this.setState({ dialogOpen:  false });
  }

  submitDialog(data) {
    this.setState({ dialogOpen:  false });
    this.setState({ rental:  data.rental });
    this.updateRentalData(data.rental);
  }

  handleFlowClickOpen(data, index) {
    this.setState({ flowEdit:  data });
    this.setState({ flowEditIndex:  index });
    this.setState({ dialogFlowOpen:  true });
  }

  updateRentalData(data)
  {
    AutodecApi.endpoints.rentals.update(data)
  }

  updateRental()
  {
    this.setState({ loading: false });
    AutodecApi.endpoints.rentals.update(this.state.rental)
  }

  handleBack()
  {
    var rentailId = this.state.rental.id;
    var currentStatus = this.state.rental.status;

    if(currentStatus >= 1 && currentStatus <= 7)
    {
      currentStatus--;
      this.state.rental.status = currentStatus;
      this.setState({ loading: true });

      AutodecApi.endpoints.rentals.update(this.state.rental)
        .then((rentalsGet) => {
          if(rentalsGet)
          {
            this.updateView();
          }
        });
    }
  }

  handleForward()
  {
    var rentailId = this.state.rental.id;
    var currentStatus = this.state.rental.status;

    if(currentStatus >= 1 && currentStatus <= 7)
    {
      currentStatus++;
      this.state.rental.status = currentStatus;
      this.setState({ loading: true });

      AutodecApi.endpoints.rentals.update(this.state.rental)
        .then((rentalsGet) => {
          if(rentalsGet)
          {
            this.updateView();
          }
        });
    }
  }

  uploadImage = (event) => {

    this.setState({ loading: true });

    var rentailId = this.state.rental.id;

    const formData = new FormData();

    var location = event.target.getAttribute('location');
    var name = "";

    switch (location)
    {
      case 'frontImage': name = "Front Ansicht";  break;
      case 'frontImageLeft': name = "Vorne Links";  break;
      case 'frontImageRight': name = "Vorne Rechts";  break;
      case 'backImage': name = "Rückseite";  break;
      case 'backImageRight': name = "Rückseite Rechts";  break;
      case 'backImageLeft': name = "Rückseite Links";  break;
      case 'sideImageLeft': name = "Linke Seite";  break;
      case 'sideImageRight': name = "Rechte Seite";  break;
      case 'frontImageAfter': name = "Front Ansicht";  break;
      case 'frontImageLeftAfter': name = "Vorne Links";  break;
      case 'frontImageRightAfter': name = "Vorne Rechts";  break;
      case 'backImageAfter': name = "Rückseite";  break;
      case 'backImageRightAfter': name = "Rückseite Rechts";  break;
      case 'backImageLeftAfter': name = "Rückseite Links";  break;
      case 'sideImageLeftAfter': name = "Linke Seite";  break;
      case 'sideImageRightAfter': name = "Rechte Seite";  break;
    }

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', location);
    formData.append('name', name);
    formData.append('unikey', uuid());

    AutodecApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.rental[location] = getRequest.data.id;

          AutodecApi.endpoints.rentals.patch({ id: rentailId}, { [location]: getRequest.data.id })
            .then((rentalsGet) => {
              if(rentalsGet)
              {
                this.setState({ loading: false });
                this.updateView();
              }
            });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };


  handleClickDelete()
  {
    /*AutodecApi.endpoints.rentals.delete(this.state.rental);
    const timeout = setTimeout(() => {
      this.props.history.push('/rentals')
    }, 1000);*/
  }

  generateReceipt()
  {
    var rentalLeaseCreate = this.state.rentalLeaseReceiptPDF;

    rentalLeaseCreate.rentalLease = this.state.rental.id;

    AutodecApi.endpoints.rentalReaseReceiptPdf.create(rentalLeaseCreate)
      .then((rentalsGet) => {
        this.state.rentalLeaseReceiptPDF = rentalsGet.data;
        this.setState({ loading: false });
        this.state.rental.rentalLeaseReceiptPDF = rentalsGet.data.id;
        this.updateRental()
        this.updateView();
        this.handleForward();
      });
    }

  generatePDF()
  {
    var rentalLeaseCreate = this.state.rentalLeasePDF;

    rentalLeaseCreate.rentalLease = this.state.rental.id;

    AutodecApi.endpoints.rentalReasePdf.create(rentalLeaseCreate)
      .then((rentalsGet) => {
        this.state.rentalLeasePDF = rentalsGet.data;
        this.setState({ loading: false });
        this.state.rental.rentalLeasePDF = rentalsGet.data.id;
        this.updateRental()
        this.updateView();
        this.handleForward();
      });
  }

  changeSignature()
  {
    var imagedata = this.sigCanvas.current.toDataURL("image/png");

    this.setState({ loading: true });

    var rentailId = this.state.rental.id;

    var formData = new FormData();

    // Update the formData object
    formData.append("image", this.dataURLtoBlob(imagedata), 'signature-customer.png');
    formData.append('type', 'signature');
    formData.append('name', 'Unterschrift Kunde');
    formData.append('unikey', uuid());

    AutodecApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.rental.signature = getRequest.data.id;

          AutodecApi.endpoints.rentals.patch({ id: rentailId}, { signature: getRequest.data.id })
            .then((rentalsGet) => {

              var imagedataWorker = this.sigCanvasWorker.current.toDataURL("image/png");

              this.setState({ loading: true });

              var rentailId = this.state.rental.id;

              var formDataWorker = new FormData();

              // Update the formData object
              formDataWorker.append("image", this.dataURLtoBlob(imagedataWorker), 'signature-worker.png');
              formDataWorker.append('type', 'signature');
              formDataWorker.append('name', 'Unterschrift Mitarbeiter');
              formDataWorker.append('unikey', uuid());

              AutodecApi.endpoints.image.create(formDataWorker)
                .then((getRequest) => {
                  try
                  { // eslint-disable-next-line
                    this.state.rental.signatureWorker = getRequest.data.id;

                    AutodecApi.endpoints.rentals.patch({ id: rentailId}, { signatureWorker: getRequest.data.id })
                      .then((rentalsGet) => {
                        if(rentalsGet)
                        {
                          this.generatePDF();
                        }
                      });
                  }
                  catch(e)
                  {
                    console.log(e);
                  }
                });

            });
        }
        catch(e)
        {
          console.log(e);
        }
      });
  }

  dataURLtoBlob(dataurl)
  {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], {type:"image/png"});
  }

  async getBase64FromUrl(url)
  {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }


  updateView()
  {
    AutodecApi.endpoints.rentalsView.getOne({ id: this.props.match.params.id })
      .then((rentalsGet) => {
        if(rentalsGet)
        {
          this.setState({ rentalView:  rentalsGet.data });
          this.setState({ loading: false })

          if(this.state.rentalView.signature.image && this.sigCanvas.current)
          {
            this.getBase64FromUrl(this.state.rentalView.signature.image['full']).then((img) => {this.sigCanvas.current.fromDataURL(img)})
          };

          if(this.state.rentalView.signatureWorker.image && this.sigCanvasWorker.current)
          {
            this.getBase64FromUrl(this.state.rentalView.signatureWorker.image['full']).then((img) => {this.sigCanvasWorker.current.fromDataURL(img)})
          };
        }
      });

      AutodecApi.endpoints.rentalLease.getAll()
        .then((rentalsLeaseGet) => {
          if(rentalsLeaseGet)
          {
            this.setState({ rentalLeases:  rentalsLeaseGet.data.results });
            this.setState({ loading: false });
          }
        });
  }

  isCanvasBlank(canvas)
  {
    if(canvas.current)
    {
      return canvas.current.isEmpty() ? false : true;
    }
  }

  changeDate(type,object)
  {
    var discount = this.state.rental.discount;
    switch (type)
    {
      case 'discount':
          if(object >= 0 || object <= 100)
          {
            this.state.rental.discount = object;
            discount = object;
          }
        break;
      case 'start':
          this.state.rental.start = object;
        break;
      case 'end':
          this.state.rental.end = object;
        break;
    }

    if(dayjs(this.state.rental.start).isBefore(this.state.rental.end))
    {
      var calculatedPrice = 0;
      var discountPrice = 0;
      var calculatedPrice = 0;

      var daysCount = dayjs(this.state.rental.end).diff(this.state.rental.start, 'day', true);
      var daysRoundend = Math.ceil(daysCount*2)/2

      var lastDays = daysRoundend;

      var month = Math.floor(lastDays / 30);
      lastDays = parseFloat(lastDays - (month * 30)).toFixed(2);

      var week = Math.floor(lastDays / 7);
      lastDays = parseFloat(lastDays - (week * 7)).toFixed(2);

      var day = Math.floor(lastDays / 1);
      lastDays = parseFloat(lastDays - (day * 1)).toFixed(2);

      var halfday = Math.floor(lastDays / 0.5);
      lastDays = parseFloat(lastDays - (halfday * 0.5)).toFixed(2);

      var priceMonth = this.state.rentalView.car.monthPrice;
      var priceWeek = this.state.rentalView.car.weekPrice;
      var priceDay = this.state.rentalView.car.dayPrice;
      var priceHalfDay = this.state.rentalView.car.halfDayPrice;

      var calculatedPriceMonth = month * priceMonth;
      var calculatedPriceWeek = week * priceWeek;
      var calculatedPriceDay = day * priceDay;
      var calculatedPriceHalfDay = halfday * priceHalfDay;

      var calculatedPrice = calculatedPriceMonth + calculatedPriceWeek + calculatedPriceDay + calculatedPriceHalfDay;

      var priceEnd = (parseInt(calculatedPrice) - ((parseInt(calculatedPrice) / 100) * discount));

      this.state.rental.priceCalc = calculatedPrice;
      this.state.rental.priceEnd = priceEnd;
    }

    this.setState({ loading: false });
    this.updateRental();
  }


  componentDidMount() {
    AutodecApi.endpoints.rentals.getOne({ id: this.props.match.params.id })
      .then((rentalsGet) => {
        if(rentalsGet)
        {
          this.setState({ rental:  rentalsGet.data });
          this.setState({ loading: false });

          this.interval = setInterval(() => this.setState(
              AutodecApi.endpoints.carRouteView.getAllFilter({filter: this.state.rental.car})
                .then((rentalsGet) => {
                  if(rentalsGet)
                  {
                    var coordinates = [];

                    for (var i = 0; i < rentalsGet.data.results.length; i++)
                    {
                      var pusher = new Array(rentalsGet.data.results[i].lat, rentalsGet.data.results[i].long);
                      coordinates.push(pusher);
                    }

                    this.state.route.features[0].geometry.coordinates = coordinates;
                    this.setState({ loading: false });
                  }
                })
          ), 5000);

        }
      });

    this.updateView();
  }

  componentWillUnmount()
  {
    clearInterval(this.interval);
  }

  render() {
    return (
      <SecuredZone title="Vermietung"
        breadcrumbs={{ '/': 'Dashboard', '/rentals':'Vermietungen', [`/rentals/${this.props.match.params.id}`] : `${this.state.rentalView.car.make} ${this.state.rentalView.car.model}` }}
        menu={{ 'Löschen': this.handleClickDelete.bind(this),'Bearbeiten': this.handleClickOpen.bind(this) }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">

          <Dialog open={this.state.dialogOpen} onClose={this.handleClose.bind(this)}>
            <RentalDialog rentalData={this.state.rental}  close={this.handleClose.bind(this)} submit={this.submitDialog.bind(this)}/>
          </Dialog>

          <Card width="1" title="Status" data={

            <div>
              <div className="rentals-steps-box-numbers">
                <Stepper activeStep={(this.state.rental.status - 1)}>
                  <Step key="step-1">
                    <StepLabel>Vertrag Aufsetzen</StepLabel>
                  </Step>
                  <Step key="step-2">
                    <StepLabel>Bilder Hinzufügen</StepLabel>
                  </Step>
                  <Step key="step-3">
                    <StepLabel>Vertrag Unterschreiben</StepLabel>
                  </Step>
                  <Step key="step-4">
                    <StepLabel>Fahrzeug Herausgeben</StepLabel>
                  </Step>
                  <Step key="step-5">
                    <StepLabel>Bilder Hinzufügen</StepLabel>
                  </Step>
                  <Step key="step-6">
                    <StepLabel>Quittung</StepLabel>
                  </Step>
                </Stepper>
              </div>
              {{
                1: <div className="online-termin-container">
                    <div className="rentals-steps-content-title">Vertrag Aufsetzen</div>
                      <div className="rentals-one-line-date">
                        <DateTimePicker
                          autoFocus
                          adapterLocale="de"
                          margin="dense"
                          id="make"
                          label="Start Datum"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={dayjs(this.state.rental.start)}
                          onChange={event => this.changeDate('start',event.$d.toISOString())}
                        />
                        <span></span>
                        <NativeSelect label="Mietvertrag" value={this.state.rental.rentalLease} variant="outlined" onChange={linkState(this, 'rental.rentalLease')}>
                          <option disabled value="null" selected="selected">Auswählen</option>
                          { this.state.rentalLeases ?
                              (this.state.rentalLeases.map((row) => (
                                 <option key={row.id} value={row.id}>{row.title}</option>
                                )
                              ))
                            :
                            ''
                          }
                        </NativeSelect>
                        <span></span>
                        <DateTimePicker
                          autoFocus
                          adapterLocale="de"
                          margin="dense"
                          id="model"
                          label="End Datum"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={dayjs(this.state.rental.end)}
                          onChange={event => this.changeDate('end',event.$d.toISOString())}
                        />
                      </div>
                      <div className="flow-line-two-rental-price">
                        <div className="price-show-box">
                          <div className="price-show-title">Berechneter Preis</div>
                          <div className="price-show-content">{this.state.rental.priceCalc} CHF</div>
                        </div>
                        <span></span>
                        <TextField
                          autoFocus
                          margin="dense"
                          id="name"
                          label="Rabatt"
                          type="text"
                          fullWidth
                          variant="outlined"
                          value={this.state.rental.discount}
                          onInput={event => this.changeDate('discount',event.target.value)}
                        />
                        <span></span>
                        <div className="price-show-box">
                          <div className="price-show-title">Endgültiger Preis</div>
                          <div className="price-show-content">{this.state.rental.priceEnd} CHF</div>
                        </div>
                      </div>

                      <div className="rentals-steps-buttons">
                        <span></span>
                        <span></span>
                        {(this.state.rental.start !== null &&
                          this.state.rental.end !== null &&
                          this.state.rental.priceCalc !== null &&
                          this.state.rental.rentalLease !== null &&
                          this.state.rental.priceEnd !== null &&
                          this.state.rental.discount !== null ? <Button variant="outlined" newStep="2" onClick={this.handleForward.bind(this)}>Nächster Schritt</Button> : '')}
                      </div>
                    </div>,
                2: <div className="online-termin-container">
                      <div className="rentals-steps-content-title">Bilder hinzufügen</div>
                      <div className="rentals-steps-content">

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Frontansicht</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImage ? <img className="image-upload-display-image" src={this.state.rentalView.frontImage.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImage" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Vorne Links</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImageLeft ? <img className="image-upload-display-image" src={this.state.rentalView.frontImageLeft.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImageLeft" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Vorne Rechts</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImageRight ? <img className="image-upload-display-image" src={this.state.rentalView.frontImageRight.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImageRight" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImage ? <img className="image-upload-display-image" src={this.state.rentalView.backImage.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImage" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite Rechts</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImageRight ? <img className="image-upload-display-image" src={this.state.rentalView.backImageRight.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImageRight" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite Links</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImageLeft ? <img className="image-upload-display-image" src={this.state.rentalView.backImageLeft.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImageLeft" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Linke Seite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.sideImageLeft ? <img className="image-upload-display-image" src={this.state.rentalView.sideImageLeft.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="sideImageLeft" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rechte Seite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.sideImageRight ? <img className="image-upload-display-image" src={this.state.rentalView.sideImageRight.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="sideImageRight" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                      </div>
                      <div className="rentals-steps-buttons">
                        <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                        <span></span>
                        {(this.state.rental.frontImage !== null &&
                          this.state.rental.frontImageLeft !== null &&
                          this.state.rental.frontImageRight !== null &&
                          this.state.rental.backImage !== null &&
                          this.state.rental.backImageRight !== null &&
                          this.state.rental.backImageLeft !== null &&
                          this.state.rental.sideImageLeft !== null &&
                          this.state.rental.sideImageRight !== null ? <Button variant="outlined" newStep="3" onClick={this.handleForward.bind(this)}>Nächster Schritt</Button> : '')}
                      </div>
                    </div>,
                3: <div className="online-termin-container">
                      <div className="rentals-steps-content-title">Vertrag Unterschreiben</div>

                      <div className="rentals-lease-show">
                        {this.state.rentalView.rentalLease ?
                            <div className="rentals-lease-show-text" dangerouslySetInnerHTML={{ __html: this.state.rentalView.rentalLease.text }} />
                            :
                            ''
                        }
                        <div className="rental-lease-signature">
                          <SignatureCanvas ref={this.sigCanvas} penColor='blue' canvasProps={{width: 400, zIndex:"10", height: 200, className: 'sigCanvas'}} />
                          <div className="rental-lease-signature-text">Kunde</div>
                        </div>
                        <div className="rental-lease-signature">
                          <SignatureCanvas ref={this.sigCanvasWorker} penColor='red' canvasProps={{width: 400, zIndex:"10", height: 200, className: 'sigCanvasWorkers'}} />
                          <div className="rental-lease-signature-text">Mitarbeiter</div>
                        </div>
                      </div>

                      <div className="rentals-steps-buttons">
                        <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                        <span></span>
                        {((this.isCanvasBlank(this.sigCanvas) || this.state.rental.signature !== null) && (this.isCanvasBlank(this.sigCanvasWorker) || this.state.rental.signatureWorker !== null) ? <Button variant="outlined" newStep="4" onClick={this.changeSignature.bind(this)}>Nächster Schritt</Button> : '')}
                      </div>
                    </div>,
                4: <div className="online-termin-container">
                      <div className="rentals-steps-content-title">Fahrzeug Herausgeben</div>

                      <embed class="display-cloud-pdf" src={(this.state.rentalView.rentalLeasePDF ? (`https://api.autodec.ch//media/${this.state.rentalView.rentalLeasePDF.filePath}`) : '')} type="application/pdf" frameborder="0" scrolling="auto" width="100%" height="100%">
                      </embed>

                      <div className="rentals-steps-buttons">
                        <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                        <span></span>
                        {(this.state.rental.changeSignatureWorker !== null ? <Button variant="outlined" newStep="5" onClick={this.handleForward.bind(this)}>Nächster Schritt</Button> : '')}
                      </div>
                    </div>,
                5: <div className="online-termin-container">
                      <div className="rentals-steps-content-title">Bilder hinzufügen</div>
                      <div className="rentals-steps-content">

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Frontansicht</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImageAfter ? <img className="image-upload-display-image" src={this.state.rentalView.frontImageAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImageAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Vorne Links</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImageLeftAfter ? <img className="image-upload-display-image" src={this.state.rentalView.frontImageLeftAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImageLeftAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Vorne Rechts</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.frontImageRightAfter ? <img className="image-upload-display-image" src={this.state.rentalView.frontImageRightAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="frontImageRightAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImageAfter ? <img className="image-upload-display-image" src={this.state.rentalView.backImageAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImageAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite Rechts</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImageRightAfter ? <img className="image-upload-display-image" src={this.state.rentalView.backImageRightAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImageRightAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rückseite Links</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.backImageLeftAfter ? <img className="image-upload-display-image" src={this.state.rentalView.backImageLeftAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="backImageLeftAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Linke Seite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.sideImageLeftAfter ? <img className="image-upload-display-image" src={this.state.rentalView.sideImageLeftAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="sideImageLeftAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                        <div className="rentals-steps-content-image-box">
                          <div className="rentals-steps-content-image-box-title">Rechte Seite</div>
                          <div style={{height:'170px'}} className="image-upload">
                            <div className="image-upload-display"></div>
                            {(this.state.rentalView.sideImageRightAfter ? <img className="image-upload-display-image" src={this.state.rentalView.sideImageRightAfter.image['400']}></img> : <AddIcon></AddIcon>)}
                            <input className="image-upload-input" location="sideImageRightAfter" onChange={this.uploadImage} type="file"/>
                          </div>
                        </div>

                      </div>
                      <div className="rentals-steps-buttons">
                        <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                        <span></span>
                        {(this.state.rental.frontImageAfter !== null &&
                          this.state.rental.frontImageLeftAfter !== null &&
                          this.state.rental.frontImageRightAfter !== null &&
                          this.state.rental.backImageAfter !== null &&
                          this.state.rental.backImageRightAfter !== null &&
                          this.state.rental.backImageLeftAfter !== null &&
                          this.state.rental.sideImageLeftAfter !== null &&
                          this.state.rental.sideImageRightAfter !== null ? <Button variant="outlined" newStep="6" onClick={this.generateReceipt.bind(this)}>Nächster Schritt</Button> : '')}
                      </div>
                    </div>,
                6: <div className="online-termin-container">
                      <div className="rentals-steps-content-title">Quittung</div>

                      <embed class="display-cloud-pdf" src={(this.state.rentalView.rentalLeaseReceiptPDF ? (`https://api.autodec.ch//media/${this.state.rentalView.rentalLeaseReceiptPDF.filePath}`) : '')} type="application/pdf" frameborder="0" scrolling="auto" width="100%" height="100%">
                      </embed>

                      <div className="rentals-steps-buttons">
                        <Button variant="outlined" onClick={this.handleBack.bind(this)}>Zurück</Button>
                        <span></span>
                        <span></span>
                      </div>
                    </div>,
              }[this.state.rental.status]}
            </div>

          }
          />

          <Card width="1" title="Standort" data=<DisplayCardLocation route={this.state.route} />/>
        </LocalizationProvider>
      </SecuredZone>
    );
  }
}

export function DisplayCardMap(props) {
  return (
    <div className="main-registration-image">
      {props.image ?
        <div>
          !! Image
        </div>
        :
        <div>
          <img className="registration-image-contain" src={RegDummy} alt="Führerausweis" />
        </div>
      }
    </div>
  );
}

export function DisplayCardCurrent(props) {
  return (
    <div style={{color: props.data.color.hex,height:"290px"}} className="current-display-current">
      {props.data.display}
    </div>
  );
}

export function DisplayCardChartsHumidity(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="%">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartHumidity />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

export function DisplayCardChartsTemperature(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="°C">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartTemperature />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

const CustomTooltipChartTemperature = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} °C</div>
      </div>
    );
  }

  return null;
};

const CustomTooltipChartHumidity = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} %</div>
      </div>
    );
  }

  return null;
};

class DisplayCardSettings extends Component {
  constructor(props)
  {
    super(props);

    this.state = props.displayData;
  }

  render() {
    return (
      <div style={{maxWidth:'360px', height:'270px', margin:'auto', marginTop:'67px'}}>
        <div className="current-display-settings">
          <div className="current-display-settings-title">Farbe</div>
          <div className="current-display-settings-color">
            <CirclePicker className={this.state.color.hex === '#ffffff' ? 'white-selected' : ''} onChangeComplete={(event) => { this.props.updateRentalColor(event) }} value={this.state.color.hex} color={this.state.color.hex} colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#fff"]}>
            </CirclePicker>
          </div>
        </div>
        <div style={{height:'50px'}} className="current-display-settings">
          <div className="current-display-settings-title">Helligkeit</div>
          <div className="current-display-settings-slider">
            <Slider value={this.state.brightness} defaultValue={60}
                    step={5}
                    marks
                    min={10}
                    max={255}
                    onChangeCommitted={(event,data) => { this.props.updateRentalBrightness(data) }}
              />
          </div>
        </div>
      </div>
    );
  }
}


class DisplayCardLocation extends Component {
  constructor(props)
  {
    super(props);
  }

  render() {
    return (
      <div className="map-display-rentals">
          <Map style={{height:'100%'}} defaultCenter={[50.879, 4.6997]} defaultZoom={15}>
            <GeoJson svgAttributes={{
                  fill: "transparent",
                  strokeWidth: "1",
                  stroke: "black",
                }} data={this.props.route}/>
          </Map>
      </div>
    );
  }
}

export default RentalOpen;
