import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import RestoreIcon from '@material-ui/icons/Restore';
import RentalLeasesDialog from '../../components/dialogs/rental-leases-dialog/rental-leases-dialog';
import moment from 'moment-timezone';

const AutodecApi = new API();

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogRentalLease: false,
      dialogRentalLeaseEdit: false,
      rentalLeases: {},
      rentalLeaseEdit: {}
    };
  }

  componentDidMount() {
    this.getRentalLease();
  }

  getRentalLease()
  {
    AutodecApi.endpoints.rentalLease.getAll()
      .then((rentalLeaseGet) => {
        this.setState({ rentalLeases:  rentalLeaseGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeRentalLease()
  {
    this.setState({ dialogRentalLease:  false });
  }

  openRentalLease() {
    this.setState({ dialogRentalLease:  true });
  }


  closeRentalLeaseEdit()
  {
    this.setState({ dialogRentalLeaseEdit:  false });
  }

  openRentalLeaseEdit(data)
  {
    console.log(data);
    this.setState({ rentalLeaseEdit:  data });
    this.setState({ dialogRentalLeaseEdit:  true });
  }


  deleteRentalLease(rentalLease) {
    this.setState({ loading: true });
    AutodecApi.endpoints.rentalLease.delete( { id: rentalLease } )
      .then(() => {
        this.setState({ loading: false });
        this.getRentalLease();
      });
  }

  submitRentalLease(data)
  {
    this.closeRentalLease();
    this.setState({ loading: true });


    AutodecApi.endpoints.rentalLease.create(data.rentalLease)
      .then(() => {
        this.setState({ loading: false });
        this.getRentalLease();
      });
  }

  updateRentalLease(data)
  {
    this.closeRentalLeaseEdit();
    this.setState({ loading: true });

    AutodecApi.endpoints.rentalLease.update(data.rentalLease)
      .then(() => {
        this.setState({ loading: false });
        this.getRentalLease();
      });
  }

  render() {
    return (
      <SecuredZone title="Einstellungen"
        breadcrumbs={{ '/': 'Dashboard', '/settings':'Einstellungen' , '/settings/rental-leases':'Mietverträge'}}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Mietvertrag': this.openRentalLease.bind(this)}}
      >
        <Dialog maxWidth="xl" fullWidt open={this.state.dialogRentalLease} onClose={this.closeRentalLease.bind(this)}>
          <RentalLeasesDialog close={this.closeRentalLease.bind(this)} submit={this.submitRentalLease.bind(this)}/>
        </Dialog>

        <Dialog maxWidth="xl" fullWidt open={this.state.dialogRentalLeaseEdit} onClose={this.closeRentalLeaseEdit.bind(this)}>
          <RentalLeasesDialog close={this.closeRentalLeaseEdit.bind(this)} rentalLease={this.state.rentalLeaseEdit} submit={this.updateRentalLease.bind(this)}/>
        </Dialog>

        <div className="settings-title">Mietverträge</div>
        <TableContainer className="settings-table" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Erstellt</TableCell>
                <TableCell width={60} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Array.isArray(this.state.rentalLeases) ?
                <>
                  {this.state.rentalLeases.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.title}</TableCell>
                      <TableCell align="left">{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                      <TableCell size="small">
                        <div className="table-buttons">
                          <IconButton onClick={() => {this.deleteRentalLease(row.id)}} size="small">
                              <DeleteIcon fontSize="inherit" />
                          </IconButton>
                          <span></span>
                          <IconButton onClick={() => {this.openRentalLeaseEdit(row)}} size="small">
                              <EditIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
                :
                <>
                </>
              }

            </TableBody>
          </Table>
        </TableContainer>

      </SecuredZone>
    );
  }
}

export default Settings;
