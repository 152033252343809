import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RentalDialog from '../../components/dialogs/rental-dialog/rental-dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";
import TextField from '@mui/material/TextField';

const AutodecApi = new API();

class Rentals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogRental: false,
      rentals: {
        unikey:'',
        token:'',
        name:'',
        create:'',
        active:'',
        displayData: [],
      }
    };
  }

  componentDidMount() {
    this.getRentals();
  }

  getRentals()
  {
    AutodecApi.endpoints.rentalsView.getAll()
      .then((rentalsGet) => {
        this.setState({ rentals:  rentalsGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeRental()
  {
    this.setState({ dialogRental:  false });
  }

  openRental() {
    this.setState({ dialogRental:  true });
  }

/*  deletePanelToken(panelToken) {
    console.log(panelToken);
    this.setState({ loading: true });
    AutodecApi.endpoints.panelsToken.delete( { id: panelToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getRentals();
      });
  }*/

  submitRental(data)
  {
    this.closeRental();
    this.setState({ loading: true });
    if(data.isAddMode)
    {
      this.state.rentals.push(data.rental)
    }

    AutodecApi.endpoints.rentals.create(data.rental)
      .then(() => {
        this.setState({ loading: false });
        this.getRentals();
      });
  }

  render() {
    return (
      <SecuredZone title="Vermietungen"
        breadcrumbs={{ '/': 'Dashboard', '/rentals':'Vermietungen' }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Vermietungen Hinzufügen': this.openRental.bind(this)}}
      >
        <Dialog open={this.state.dialogRental} onClose={this.closeRental.bind(this)}>
          <RentalDialog close={this.closeRental.bind(this)} submit={this.submitRental.bind(this)}/>
        </Dialog>

        <div className="search-box">
          <TextField className="search-box-input" id="standard-basic" label="Mietverträge Suchen" variant="standard" />
        </div>

        <TableContainer className="settings-table" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kunde</TableCell>
                <TableCell align="left">Fahrzeug</TableCell>
                <TableCell align="left">Nächster Schritt</TableCell>
                <TableCell align="left">Status</TableCell>
                <TableCell align="left">Beginn</TableCell>
                <TableCell align="left">Ende</TableCell>
                <TableCell width={60} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Array.isArray(this.state.rentals) ?
                <>
                  {this.state.rentals.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.customer.firstName} {row.customer.secondName}</TableCell>
                      <TableCell align="left">{row.car.make} {row.car.model}</TableCell>
                      <TableCell align="left">{
                        {
                          1: "1. Vertrag Aufsetzen",
                          2: "2. Bilder Hinzufügen",
                          3: "3. Vertrag Unterschrift",
                          4: "4. Fahrzeug Herausgeben",
                          5: "5. Bilder Hinzufügen",
                          6: "6. Abgeschlossen",
                        }[row.status]
                      }</TableCell>
                      <TableCell align="left">{
                        {
                          1: "Erstellt",
                          2: "Offen",
                          3: "Offen",
                          4: "Beim Kunden",
                          5: "Rückname",
                          6: "Abgeschlossen"
                        }[row.status]
                      }</TableCell>
                      <TableCell align="left">{(row.end ? moment(row.begin).format('DD.MM.YYYY HH:mm') : 'Offen')}</TableCell>
                      <TableCell align="left">{(row.end ? moment(row.end).format('DD.MM.YYYY HH:mm') : 'Offen')}</TableCell>
                      <TableCell size="small">
                        <div className="table-buttons">
                          <IconButton component={Link} to={`rentals/${row.id}`}  size="small">
                            <OpenInNewIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
                :
                <>
                </>
              }

            </TableBody>
          </Table>
        </TableContainer>
      </SecuredZone>
    );
  }
}

export default Rentals;
