import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment-timezone';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import CarDialog from '../../components/dialogs/car-dialog/car-dialog';
import { Link } from "react-router-dom";
import ArticleIcon from '@mui/icons-material/Article';
import PasswordIcon from '@mui/icons-material/Password';
import ApartmentIcon from '@mui/icons-material/Apartment';

const AutodecApi = new API();

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogCarToken: false,
      carTokens: {}
    };
  }

  componentDidMount() {
    this.getCarToken();
  }

  getCarToken()
  {
    AutodecApi.endpoints.carsDataTokenView.getAll()
      .then((carTokenGet) => {
        this.setState({ carTokens:  carTokenGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeCarToken()
  {
    this.setState({ dialogCarToken:  false });
  }

  openCarToken() {
    this.setState({ dialogCarToken:  true });
  }

  deleteCarToken(carToken) {
    this.setState({ loading: true });
    AutodecApi.endpoints.carToken.delete( { id: carToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getCarToken();
      });
  }

  submitCarToken(data)
  {
    this.closeCarToken();
    this.setState({ loading: true });
    if(data.isAddMode)
    {
      this.state.carTokens.push(data.carToken)
    }

    AutodecApi.endpoints.carToken.create(data.carToken)
      .then(() => {
        this.setState({ loading: false });
        this.getCarToken();
      });
  }

  render() {
    return (
      <SecuredZone title="Einstellungen"
        breadcrumbs={{ '/': 'Dashboard', '/settings':'Einstellungen' }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{}}
      >

        <div className="settings-title">Allgemein</div>
        <Grid
          container
          direction="row"
          style={{margin:'50px',width:"calc(100% - 100px)"}}
        >
          <Card key='ControllerToken' className="settings-card">
            <CardActionArea component={Link} to={`/settings/controller-token/`}>
              <CardMedia height="50">
                <SettingsRemoteIcon className="settings-card-image"></SettingsRemoteIcon>
              </CardMedia>
              <div className="settings-card-name">Controller Token</div>
            </CardActionArea>
          </Card>

          <Card key='ControllerToken' className="settings-card">
            <CardActionArea component={Link} to={`/settings/rental-leases/`}>
              <CardMedia height="50">
                <ArticleIcon className="settings-card-image"></ArticleIcon>
              </CardMedia>
              <div className="settings-card-name">Mietverträge</div>
            </CardActionArea>
          </Card>
        </Grid>

        <div className="settings-title">Benutzer</div>
        <Grid
          container
          direction="row"
          style={{margin:'50px',width:"calc(100% - 100px)"}}
        >
          <Card key='ControllerToken' className="settings-card">
            <CardActionArea component={Link} to={`/settings/company-data/`}>
              <CardMedia height="50">
                <ApartmentIcon className="settings-card-image"></ApartmentIcon>
              </CardMedia>
              <div className="settings-card-name">Firmen-Daten</div>
            </CardActionArea>
          </Card>

          <Card key='ControllerToken' className="settings-card">
            <CardActionArea component={Link} to={`/settings/controller-token/`}>
              <CardMedia height="50">
                <PasswordIcon className="settings-card-image"></PasswordIcon>
              </CardMedia>
              <div className="settings-card-name">Passwort</div>
            </CardActionArea>
          </Card>
        </Grid>

      </SecuredZone>
    );
  }
}

export default Settings;
