import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FeaturedVideoIcon from '@material-ui/icons/FeaturedVideo';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from "react-router-dom";
import ICommunity from '../../images/icommunity.png';
import Logo from '../../images/logo.svg';
import ReceiptIcon from '@material-ui/icons/Receipt';
import LockIcon from '@material-ui/icons/Lock';
import IconButton from '@material-ui/core/IconButton';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DescriptionIcon from '@mui/icons-material/Description';
import CarCrashIcon from '@mui/icons-material/CarCrash';

import '../../css/sidebar.css';

export default function SideBar() {

  const useStyles = makeStyles({
    listItemText:{
      fontSize:'1.3em',
    }
  });

  const classes = useStyles();

  return (
    <>
      <div className="top-bar-logo-container">
        <Link to="/">
          <img className="top-bar-logo" src={Logo} alt="Logo" />
        </Link>
      </div>
      <List>
        <ListItem button component={Link} to="/" key="Dashboard">
          <ListItemIcon ><DashboardIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/calendar" key="Kalender">
          <ListItemIcon><CalendarMonthIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Kalender" />
        </ListItem>
        <ListItem button component={Link} to="/customers" key="Kunden">
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Kunden" />
        </ListItem>
        <ListItem button component={Link} to="/cars" key="Fahrzeuge">
          <ListItemIcon><LocalShippingIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Fahrzeuge" />
        </ListItem>
        <ListItem button component={Link} to="/rentals" key="Vermietungen">
          <ListItemIcon><ReceiptIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Vermietungen" />
        </ListItem>
        <ListItem button component={Link} to="/claims" key="Schadenfälle">
          <ListItemIcon><CarCrashIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Schadenfälle" />
        </ListItem>
        <ListItem button component={Link} to="/rentals" key="Rechnungen">
          <ListItemIcon><DescriptionIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Rechnungen" />
        </ListItem>
        <ListItem button component={Link} to="/settings" key="Einstellungen">
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Einstellungen" />
        </ListItem>
        <ListItem button component={Link} to="/auth/logout" key="Ausloggen">
          <ListItemIcon><LockIcon /></ListItemIcon>
          <ListItemText classes={{primary:classes.listItemText}} primary="Ausloggen" />
        </ListItem>
      </List>

      <div>
        <img className="bottom-bar-icommunity" src={ICommunity} alt="Logo" />
      </div>
    </>
  );
}
