import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './customer-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';


const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class CustomerDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      customer: {
        unikey: '',
        firstName: '',
        secondName: '',
        road: '',
        plz: '',
        location: '',
        email: '',
        phone: '',
        birthday: ''
      },
      loading: false,
    };

    if(props.customerData)
    {
      this.isAddMode = false;
      this.state.customer = props.customerData;
      this.inputProps = props.customerData;
    }
    else
    {
      this.state.customer.unikey = uuid();
    }
  }

  componentDidMount()
  {
    this.getCustomers();
  }

  getCustomers()
  {
    if(this.isAddMode == false)
    {
      this.setState({ loading: true });
      AutodecApi.endpoints.customers.getOne({ id:  this.inputProps.id })
        .then((customerGet) => {
          try
          {
            this.setState({ customer:  customerGet.data });
            this.setState({ loading: false });
          }
          catch(e)
          {

          }
        });
    }

  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Kunde { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="firstName"
              label="Vorname"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.firstName}
              onInput={linkState(this, 'customer.firstName')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="secondName"
              label="Nachname"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.secondName}
              onInput={linkState(this, 'customer.secondName')}
            />
          </div>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="road"
              label="Strasse"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.road}
              onInput={linkState(this, 'customer.road')}
            />
          </div>
          <div className="pt-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="plz"
              label="PLZ"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.plz}
              onInput={linkState(this, 'customer.plz')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="location"
              label="Ortschaft"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.location}
              onInput={linkState(this, 'customer.location')}
            />
          </div>
          <div className="pt-line-one">
            <TextField
              autoFocus
              margin="dense"
              id="phone"
              label="Telefonnummer"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.phone}
              onInput={linkState(this, 'customer.phone')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="road"
              label="Geburtsdatum"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.birthday}
              onInput={linkState(this, 'customer.birthday')}
            />
          </div>
          <div className="pt-line-two">
            <TextField
              autoFocus
              margin="dense"
              id="road"
              label="E-Mail Adresse"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.customer.email}
              onInput={linkState(this, 'customer.email')}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default CustomerDialog;
