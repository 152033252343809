import axios from 'axios';

axios.defaults.baseURL = 'https://api.autodec.ch/';

class API {
  constructor()
  {
    this.endpoints = {};
    this.endpoints.users = this.createBasicCRUDEndpoints({ name: 'users' });
    this.endpoints.customers = this.createBasicCRUDEndpoints({ name: 'customers' });
    this.endpoints.cars = this.createBasicCRUDEndpoints({ name: 'cars' });
    this.endpoints.rentals = this.createBasicCRUDEndpoints({ name: 'rentals' });
    this.endpoints.me = this.createBasicCRUDEndpoints({ name: 'auth/user' });
    this.endpoints.carToken = this.createBasicCRUDEndpoints({ name: 'car-token' });
    this.endpoints.rentalLease = this.createBasicCRUDEndpoints({ name: 'rental-lease' });
    this.endpoints.image = this.createBasicCRUDEndpoints({ name: 'image' });
    this.endpoints.calendarEvent = this.createBasicCRUDEndpoints({ name: 'calendar-event' });

    this.endpoints.carsView = this.createBasicCRUDEndpoints({ name: 'cars-view' });
    this.endpoints.carRouteView = this.createBasicCRUDEndpoints({ name: 'cars-route-view' });
    this.endpoints.rentalsView = this.createBasicCRUDEndpoints({ name: 'rentals-view' });
    this.endpoints.carsDataTokenView = this.createBasicCRUDEndpoints({ name: 'cars-data-token-view' });
    this.endpoints.rentalReasePdf = this.createBasicCRUDEndpoints({ name: 'rental-lease-pdf' });
    this.endpoints.rentalReaseReceiptPdf = this.createBasicCRUDEndpoints({ name: 'rental-lease-receipt-pdf' });
    this.endpoints.calendarEventFilter = this.createBasicCRUDEndpoints({ name: 'calendar-event-filter' });
  }

  createBasicCRUDEndpoints = ({ name }) => {

    var authToken = '';

    if (localStorage.getItem('token') !== null)
    {
      authToken = localStorage.getItem('token');
    }

    const endpoints = {};

    const resourceURL = `v1/${name}`;

    endpoints.getAllDate = ({ start, end }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/?start=${start}&end=${end}`, config).catch(err => this.catchFuntion(err));
    endpoints.getAllFilter = ({ filter }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/?filter=${filter}`, config).catch(err => this.catchFuntion(err));
    endpoints.getAll = (config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) =>  axios.get(`${resourceURL}/`, config).catch(err => this.catchFuntion(err));
    endpoints.getOne = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.get(`${resourceURL}/${id}/`, config).catch(err => this.catchFuntion(err));
    endpoints.create = (toCreate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.post(`${resourceURL}/`, toCreate, config).catch(err => this.catchFuntion(err));
    endpoints.update = (toUpdate, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.put(`${resourceURL}/${toUpdate.id}/`, toUpdate, config).catch(err => this.catchFuntion(err));
    endpoints.patch = ({ id }, toPatch, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.patch(`${resourceURL}/${id}/`, toPatch, config).catch(err => this.catchFuntion(err));
    endpoints.delete = ({ id }, config = { crossDomain: true, headers: { 'Authorization': ` Token ${authToken}` }}) => axios.delete(`${resourceURL}/${id}/`, config).catch(err => this.catchFuntion(err));

    return endpoints;
  }

  catchFuntion = (error) =>
  {
    var errorResponse = error.response;

    if(errorResponse)
    {
      if(errorResponse.status == 401)
      {
        localStorage.removeItem('token');
        window.location.replace("/");
      }
    }
  }
}

export default API;
