import React, { useState, useEffect } from 'react';
import Logo from '../../images/logo.svg'
import Button from '@material-ui/core/Button';

const Logout = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('token') == null) {
      window.location.replace('http://localhost:3000/auth/login');
    } else {
      setLoading(false);
    }
  }, []);

  const handleLogout = e => {
    e.preventDefault();

    fetch('https://api.autodec.ch//v1/auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('token')}`
      }
    })
      .then(res => res.json())
      .then(data => {
        localStorage.clear();
        window.location.replace('https://PLS.ch/auth/login');
      });
  };

  return (
    <div>
      {loading === false && (
        <div className="authenticate-back">
          <span></span>
          <div className="authenticate-center">
            <span></span>
            <div className="authenticate-window">
              <div className="authenticate-logo">
                <img className="authenticate-logo-image" src={Logo} alt="Logo" />
              </div>
              <div className="authenticate-title">Abmelden</div>
              <div className="authenticate-text">Möchten Sie sich wirklich abmelden?</div>
              <div className="authenticate-buttons-logout">
                <span></span>
                <Button onClick={handleLogout} variant="contained" color="default">
                  Abmelden
                </Button>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Logout;
