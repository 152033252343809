import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import BlockIcon from '@material-ui/icons/Block';
import RestoreIcon from '@material-ui/icons/Restore';
import CarTokenDialog from '../../components/dialogs/car-token-dialog/car-token-dialog';
import moment from 'moment-timezone';

const AutodecApi = new API();

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogCarToken: false,
      carTokens: {}
    };
  }

  componentDidMount() {
    this.getCarToken();
  }

  getCarToken()
  {
    AutodecApi.endpoints.carsDataTokenView.getAll()
      .then((carTokenGet) => {
        this.setState({ carTokens:  carTokenGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeCarToken()
  {
    this.setState({ dialogCarToken:  false });
  }

  openCarToken() {
    this.setState({ dialogCarToken:  true });
  }

  deleteCarToken(carToken) {
    this.setState({ loading: true });
    AutodecApi.endpoints.carToken.delete( { id: carToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getCarToken();
      });
  }

  submitCarToken(data)
  {
    this.closeCarToken();
    this.setState({ loading: true });
    if(data.isAddMode)
    {
      this.state.carTokens.push(data.carToken)
    }
    console.log(data.carToken);
    AutodecApi.endpoints.carToken.create(data.carToken)
      .then(() => {
        this.setState({ loading: false });
        this.getCarToken();
      });
  }

  render() {
    return (
      <SecuredZone title="Einstellungen"
        breadcrumbs={{ '/': 'Dashboard', '/settings':'Einstellungen' , '/settings/controller-token':'Controller Token'}}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Token Hinzufügen': this.openCarToken.bind(this)}}
      >
        <Dialog open={this.state.dialogCarToken} onClose={this.closeCarToken.bind(this)}>
          <CarTokenDialog close={this.closeCarToken.bind(this)} submit={this.submitCarToken.bind(this)}/>
        </Dialog>

        <div className="settings-title">Controller Token</div>
        <TableContainer className="settings-table" component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align="left">Fahrzeug</TableCell>
                <TableCell align="left">Token</TableCell>
                <TableCell align="left">Erstellt</TableCell>
                <TableCell width={60} align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { Array.isArray(this.state.carTokens) ?
                <>
                  {this.state.carTokens.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">{row.name}</TableCell>
                      <TableCell align="left">{row.car.make} {row.car.model}</TableCell>
                      <TableCell align="left">{row.token}</TableCell>
                      <TableCell align="left">{moment(row.created).format('DD.MM.YYYY HH:mm')}</TableCell>
                      <TableCell size="small">
                        <div className="table-buttons">
                          <IconButton onClick={() => {this.deleteCarToken(row.id)}} size="small">
                              <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
                :
                <>
                </>
              }

            </TableBody>
          </Table>
        </TableContainer>

      </SecuredZone>
    );
  }
}

export default Settings;
