import React, { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Card from '../../components/Card';
import '../../css/main.css';
import '../../css/vectormap.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomerDialog from '../../components/dialogs/customer-dialog/customer-dialog';
import Dialog from '@material-ui/core/Dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Map, Marker } from "pigeon-maps"
import { CreateButtonInherit } from '../../components/small/Buttons';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
  ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
  Label, LabelList } from 'recharts';
import { CirclePicker } from 'react-color';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import RegDummy from '../../images/reg_dummy.png';

const AutodecApi = new API();

class CustomerOpen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats:[],
      dialogOpen: false,
      loading: true,
      customer: {
        unikey: '',
        firstName: '',
        secondName: '',
        road: '',
        plz: '',
        location: '',
        email: '',
        phone: '',
        birthday: ''
      }
    };
  }

  handleClickOpen() {
    this.setState({ dialogOpen:  true });
  }

  handleClose() {
    this.setState({ dialogOpen:  false });
  }

  submitDialog(data) {
    this.setState({ dialogOpen:  false });
    this.setState({ customer:  data.customer });
    this.updateCustomerData(data.customer);
  }

  handleFlowClickOpen(data, index) {
    this.setState({ flowEdit:  data });
    this.setState({ flowEditIndex:  index });
    this.setState({ dialogFlowOpen:  true });
  }

  updateCustomerData(data)
  {
    AutodecApi.endpoints.customers.update(data)
  }

  handleClickDelete()
  {
    /*AutodecApi.endpoints.customers.delete(this.state.customer);
    const timeout = setTimeout(() => {
      this.props.history.push('/customers')
    }, 1000);*/
  }

  componentDidMount() {
    const AutodecApi = new API();
    AutodecApi.endpoints.customers.getOne({ id: this.props.match.params.id })
      .then((customersGet) => {
        if(customersGet)
        {
          this.setState({ customer:  customersGet.data });
          this.setState({ loading: false });
        }
      });

    AutodecApi.endpoints.customers.getOne({ id: this.props.match.params.id })
      .then((customersGet) => {
        if(customersGet)
        {
          this.setState({ customer:  customersGet.data });
          this.setState({ loading: false });
        }
      });

    AutodecApi.endpoints.customers.getOne({ id: this.props.match.params.id })
      .then((customersGet) => {
        if(customersGet)
        {
          this.setState({ customer:  customersGet.data });
          this.setState({ loading: false });
        }
      });

  }

  render() {
    return (
      <SecuredZone title="Kunden"
        breadcrumbs={{ '/': 'Dashboard', '/customers':'Kunden', [`/customers/${this.props.match.params.id}`] : `${this.state.customer.firstName} ${this.state.customer.secondName}` }}
        menu={{ 'Löschen': this.handleClickDelete.bind(this),'Bearbeiten': this.handleClickOpen.bind(this) }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose.bind(this)}>
          <CustomerDialog customerData={this.state.customer}  close={this.handleClose.bind(this)} submit={this.submitDialog.bind(this)}/>
        </Dialog>

        <Card width="3-5" title="Kundendaten" data={<DisplayCardCustomer data={this.state.customer}/>}/>

        <Card width="2-5" title="Fahrzeugausweis" data={<DisplayCardImage image={this.state.customer.registrationCardImage}/>}/>

        <Card width="3-5" title="Mietverträge" data={<DisplayCardRentalPDF data={this.state.customer}/>}/>

        <Card width="2-5" title="Vermietungen" data={<DisplayCardRentals image={this.state.customer}/>}/>

      </SecuredZone>
    );
  }
}


export function DisplayCardCustomer(props) {
  return (
    <div  style={{height:'300px',position:'relative',float:'left'}}>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Vorname</div>
        <div className="main-table-list-value">{props.data.firstName}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Nachname</div>
        <div className="main-table-list-value">{props.data.secondName}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Telefonnummer</div>
        <div className="main-table-list-value">{props.data.phone}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">E-Mailadresse</div>
        <div className="main-table-list-value">{props.data.email}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Strasse</div>
        <div className="main-table-list-value">{props.data.road}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Postleitzahl</div>
        <div className="main-table-list-value">{props.data.plz}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Ortschaft</div>
        <div className="main-table-list-value">{props.data.location}</div>
      </div>
    </div>
  );
}

export function DisplayCardImage(props) {
  return (
    <div className="main-registration-image" style={{height:'300px',position:'relative',float:'left'}}>
      {props.image ?
        <div>
          !! Image
        </div>
        :
        <div>
          <img className="registration-image-contain" src={RegDummy} alt="Führerausweis" />
        </div>
      }
    </div>
  );
}

export function DisplayCardRentalPDF(props) {
  return (
    <>
      <div style={{height:"240px"}} className="current-display-status">
      </div>
    </>
  );
}

export function DisplayCardRentals(props) {
  return (
    <>
      <div style={{height:"240px"}} className="current-display-status">
      </div>
    </>
  );
}

export function DisplayCardCurrent(props) {
  return (
    <div style={{color: props.data.color.hex,height:"290px"}} className="current-display-current">
      {props.data.display}
    </div>
  );
}

class DisplayCardLocation extends Component {
  render() {
    return (
      <div className="map-display">
          <Map style={{height:'100%'}} defaultCenter={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} defaultZoom={15}>
            <Marker width={30} anchor={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} /*onClick={() => setHue(hue + 20)} */>
             <div style={{color: this.props.displayData.color.hex}} class="panel-display-box">
              {(this.props.displayData.enabled ? this.props.displayData.display : "")}
             </div>
            </Marker>
          </Map>
      </div>
    );
  }
}


export default CustomerOpen;
