import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './car-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class CarDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      car: {
         unikey:'',
         make:'',
         model:'',
         trim:'',
         vin:'',
         plate:'',
         color:'',
         firstUse:'',
         lastTest:'',
         seatsFront:'',
         seatsAll:'',
         useWeight:'',
         fullWeight:'',
         emptyWeight:'',
         performance:'',
         typenumber:'',
         dayPrice:'',
         halfDayPrice:'',
         weekPrice:'',
         monthPrice: '',
         type:1,
         mkfNumber:'',
         carImage:'',
         loadRoomWidth: '',
         loadRoomHeight: '',
         loadRoomLength: '',
         carFuel: 1,
         driveType: 1,
         carHeight:'',
      },
      loading: false,
      selectedFile: null,
      upload: 'Bild Hochladen',
      snackBar: false
    };

    if(props.carData)
    {
      this.isAddMode = false;
      this.state.car = props.carData;
      this.inputProps = props.carData;
    }
    else
    {
      this.state.car.unikey = uuid();
    }
  }

  componentDidMount()
  {
    this.getCars();
  }

  uploadFile = (event) => {

    const formData = new FormData();

    // Update the formData object
    formData.append("image", event.target.files[0]);
    formData.append('type', 'service');
    formData.append('name', 'Service');
    formData.append('unikey', uuid());

    AutodecApi.endpoints.image.create(formData)
      .then((getRequest) => {
        try
        { // eslint-disable-next-line
          this.state.car.carImage = getRequest.data.id;
          this.setState({ upload: event.target.files[0].name})
        }
        catch(e)
        {
          console.log(e);
        }
      });
  };

  getCars()
  {
    if(this.isAddMode == false)
    {
      this.setState({ loading: true });
      AutodecApi.endpoints.cars.getOne({ id:  this.inputProps.id })
        .then((carGet) => {
          try
          {
            this.setState({ car:  carGet.data });
            this.setState({ loading: false });
          }
          catch(e)
          {

          }
        });
    }

  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Fahrzeug { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="make"
              label="Hersteller"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.make}
              onInput={linkState(this, 'car.make')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="model"
              label="Modell"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.model}
              onInput={linkState(this, 'car.model')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="make"
              label="Ausführung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.trim}
              onInput={linkState(this, 'car.trim')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="vin"
              label="VIN"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.vin}
              onInput={linkState(this, 'car.vin')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="plate"
              label="Nummernschild"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.plate}
              onInput={linkState(this, 'car.plate')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="color"
              label="Farbe"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.color}
              onInput={linkState(this, 'car.color')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="firstUse"
              label="Erste Zulassung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.firstUse}
              onInput={linkState(this, 'car.firstUse')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="lastTest"
              label="Letzte MFK"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.lastTest}
              onInput={linkState(this, 'car.lastTest')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="seatsFront"
              label="Vordere Sitze"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.seatsFront}
              onInput={linkState(this, 'car.seatsFront')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="seatsAll"
              label="Alle Sitze"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.seatsAll}
              onInput={linkState(this, 'car.seatsAll')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="useWeight"
              label="Nutz / Sattellast"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.useWeight}
              onInput={linkState(this, 'car.useWeight')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="fullWeight"
              label="Gesamtgewicht"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.fullWeight}
              onInput={linkState(this, 'car.fullWeight')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="emptyWeight"
              label="Leergewicht"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.emptyWeight}
              onInput={linkState(this, 'car.emptyWeight')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="performance"
              label="Leistung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.performance}
              onInput={linkState(this, 'car.performance')}
            />
          </div>

          <div className="pt-line-one-car">
            <TextField
              autoFocus
              margin="dense"
              id="typenumber"
              label="Typemgenehmigung"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.typenumber}
              onInput={linkState(this, 'car.typenumber')}
            />
            <TextField
              autoFocus
              margin="dense"
              id="mkfNumber"
              label="MFK Nummer"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.mkfNumber}
              onInput={linkState(this, 'car.mkfNumber')}
            />
          </div>

          <div className="pt-line-one-car-four">
            <TextField
              autoFocus
              margin="dense"
              id="weekPrice"
              label="Fahrzeug Höhe (m)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.carHeight}
              onInput={linkState(this, 'car.carHeight')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="dayPrice"
              label="Laderaum Länge (m)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.loadRoomLength}
              onInput={linkState(this, 'car.loadRoomLength')}
            />
          </div>

          <div className="pt-line-one-car-four">
            <TextField
              autoFocus
              margin="dense"
              id="monthPrice"
              label="Laderaum Breite (m)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.loadRoomWidth}
              onInput={linkState(this, 'car.loadRoomWidth')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="weekPrice"
              label="Laderaum Höhe (m)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.loadRoomHeight}
              onInput={linkState(this, 'car.loadRoomHeight')}
            />
          </div>

          <div className="pt-line-one-car-three">
            <TextField
              autoFocus
              margin="dense"
              id="monthPrice"
              label="Monats Preis (CHF)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.monthPrice}
              onInput={linkState(this, 'car.monthPrice')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="weekPrice"
              label="Wochen Preis (CHF)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.weekPrice}
              onInput={linkState(this, 'car.weekPrice')}
            />
          </div>

          <div className="pt-line-one-car-three">
            <TextField
              autoFocus
              margin="dense"
              id="dayPrice"
              label="Tages Preis (CHF)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.dayPrice}
              onInput={linkState(this, 'car.dayPrice')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="halfDayPrice"
              label="Halbtages Preis (CHF)"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.car.halfDayPrice}
              onInput={linkState(this, 'car.halfDayPrice')}
            />
          </div>

          <div className="pt-line-one-car-five">
            <NativeSelect value={this.state.car.type} onChange={linkState(this, 'car.type')} id="select">
              <option value="1">Personenwagen</option>
              <option value="2">Lieferwagen</option>
            </NativeSelect>
            <span></span>
            <NativeSelect value={this.state.car.carFuel} onChange={linkState(this, 'car.carFuel')} id="select">
              <option value="1">Diesel</option>
              <option value="2">Benzin</option>
              <option value="3">Elektro</option>
              <option value="4">Wasserstoff</option>
            </NativeSelect>
            <span></span>
            <NativeSelect value={this.state.car.driveType} onChange={linkState(this, 'car.driveType')} id="select">
              <option value="1">Automat</option>
              <option value="2">Geschaltet</option>
            </NativeSelect>
          </div>

          <div className="pt-line-one-car-two">
            <span></span>
            <div className="image-upload">
              <div className="image-upload-display">{this.state.upload}</div>
              <input className="image-upload-input" type="file" onChange={this.uploadFile} />
            </div>
            <span></span>
          </div>

        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default CarDialog;
