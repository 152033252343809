import React, { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Card from '../../components/Card';
import '../../css/main.css';
import '../../css/vectormap.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CarDialog from '../../components/dialogs/car-dialog/car-dialog';
import Dialog from '@material-ui/core/Dialog';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Map, Marker } from "pigeon-maps"
import { CreateButtonInherit } from '../../components/small/Buttons';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
  ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
  Label, LabelList } from 'recharts';
import { CirclePicker } from 'react-color';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';
import RegDummy from '../../images/reg_dummy.png';

const AutodecApi = new API();

class CarOpen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stats:[],
      dialogOpen: false,
      loading: true,
      status:{
        calculatedEngineLoad:'',
        coolantTemperature:'',
        engineSpeed:'',
        vehicleSpeed:'',
        runTime:'',
        distanceMil:'',
        fuelLevel:'',
        oilTemperature:'',
        oddometer:'',
        lat:'',
        long:'',
      },
      image:{
        image:{
          header:''
        }
      },
      car: {
         unikey:'',
         firstName:'',
         secondName:'',
         road:'',
         plz:'',
         location:'',
         email:'',
         phone:'',
         birthday:'',
         make:'',
         model:'',
         trim:'',
         vin:'',
         plate:'',
         color:'',
         firstUse:'',
         lastTest:'',
         seatsFront:'',
         seatsAll:'',
         useWeight:'',
         fullWeight:'',
         emptyWeight:'',
         performance:'',
         typenumber:'',
         type:1,
         mkfNumber:'',
         carImage:'',
         monthPrice: '',
         loadRoomWidth: '',
         loadRoomHeight: '',
         loadRoomLength: '',
         carFuel: '',
         driveType: '',
      }
    }
  }

  handleClickOpen() {
    this.setState({ dialogOpen:  true });
  }

  handleClose() {
    this.setState({ dialogOpen:  false });
  }

  submitDialog(data) {
    this.setState({ dialogOpen:  false });
    this.setState({ car:  data.car });
    this.updateCarData(data.car);
  }

  handleFlowClickOpen(data, index) {
    this.setState({ flowEdit:  data });
    this.setState({ flowEditIndex:  index });
    this.setState({ dialogFlowOpen:  true });
  }

  updateCarData(data)
  {
    AutodecApi.endpoints.cars.update(data)
  }

  handleClickDelete()
  {
    /*AutodecApi.endpoints.cars.delete(this.state.car);
    const timeout = setTimeout(() => {
      this.props.history.push('/cars')
    }, 1000);*/
  }

  updateMapModel()
  {
    /*AutodecApi.endpoints.image.getOne({ id: carsGet.data.carImage })
      .then((image) => {
        if(image)
        {
          this.setState({ image:  image.data });
        }
      });*/
  }

  componentDidMount() {
    const AutodecApi = new API();
    AutodecApi.endpoints.cars.getOne({ id: this.props.match.params.id })
      .then((carsGet) => {
        if(carsGet)
        {
          this.setState({ car:  carsGet.data });
          this.setState({ loading: false });

          AutodecApi.endpoints.image.getOne({ id: carsGet.data.carImage })
            .then((image) => {
              if(image)
              {
                this.setState({ image:  image.data });
              }
            });

        }
      });

  }

  render() {
    return (
      <SecuredZone title="Fahrzeuge"
        breadcrumbs={{ '/': 'Dashboard', '/cars':'Fahrzeuge', [`/cars/${this.props.match.params.id}`] : `${this.state.car.make} ${this.state.car.model}` }}
        menu={{ 'Löschen': this.handleClickDelete.bind(this),'Bearbeiten': this.handleClickOpen.bind(this) }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
      >
        <Dialog open={this.state.dialogOpen} onClose={this.handleClose.bind(this)}>
          <CarDialog carData={this.state.car}  close={this.handleClose.bind(this)} submit={this.submitDialog.bind(this)}/>
        </Dialog>

        <Card width="2-5" title="Fahrzeugspezifikationen" data={<DisplayCardCar data={this.state.car}/>}/>

        <Card width="3-5" title="Bild" data={<DisplayCardImage image={this.state.image.image['header']}/>}/>

        <Card width="3-5" title="Status" data={<DisplayCardPrice data={this.state.car}/>}/>

        <Card width="1" title="Standort" data={<DisplayCardMap/>}/>

      </SecuredZone>
    );
  }
}


export function DisplayCardCar(props) {
  return (
    <div className="table-list-car" style={{height:"700px"}}>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Hersteller</div>
        <div className="main-table-list-value">{props.data.make}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Modell</div>
        <div className="main-table-list-value">{props.data.model}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Ausführung</div>
        <div className="main-table-list-value">{props.data.trim}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">VIN</div>
        <div className="main-table-list-value">{props.data.vin}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Strasse</div>
        <div className="main-table-list-value">{props.data.plate}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Farbe</div>
        <div className="main-table-list-value">{props.data.color}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Erstzulassung</div>
        <div className="main-table-list-value">{props.data.firstUse}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Vordere Sitzplätze</div>
        <div className="main-table-list-value">{props.data.seatsFront}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Sitzplätze Insgesamt</div>
        <div className="main-table-list-value">{props.data.seatsAll}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Nutz / Sattellast</div>
        <div className="main-table-list-value">{props.data.useWeight}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Gesamtgewicht</div>
        <div className="main-table-list-value">{props.data.fullWeight}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Leergewicht</div>
        <div className="main-table-list-value">{props.data.emptyWeight}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Leistung</div>
        <div className="main-table-list-value">{props.data.performance}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Typengenehmigung</div>
        <div className="main-table-list-value">{props.data.typenumber}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Typ</div>
        <div className="main-table-list-value">{props.data.type}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">MFK Nummer</div>
        <div className="main-table-list-value">{props.data.mkfNumber}</div>
      </div>
      <div className="main-table-list-container">
        <div className="main-table-list-key">Letzte Prüfung</div>
        <div className="main-table-list-value">{props.data.lastTest}</div>
      </div>
    </div>
  );
}

export function DisplayCardImage(props) {
  return (
    <div style={{height:"455px"}} className="main-registration-image">
      {props.image ?
        <div>
          <img className="registration-image-contain" src={props.image} alt="Fahrzeug" />
        </div>
        :
        ''
      }
    </div>
  );
}

export function DisplayCardStatus(props) {
  return (
    <>
      <div style={{height:"150px"}} className="current-display-status">
      </div>
      <div className="current-display-status-switch">
      </div>
    </>
  );
}

export function DisplayCardPrice(props) {
  return (
    <>
      <div style={{height:"160px"}} className="current-display-status-price">
        <span></span>
        <div className="car-current-price-box">
          <div className="car-current-price-box-price">{props.data.monthPrice}</div>
          <div className="car-current-price-box-text">Monats Preis</div>
        </div>
        <div className="car-current-price-box">
          <div className="car-current-price-box-price">{props.data.weekPrice}</div>
          <div className="car-current-price-box-text">Wochen Preis</div>
        </div>
        <div className="car-current-price-box">
          <div className="car-current-price-box-price">{props.data.dayPrice}</div>
          <div className="car-current-price-box-text">Tages Preis</div>
        </div>
        <div className="car-current-price-box">
          <div className="car-current-price-box-price">{props.data.halfDayPrice}</div>
          <div className="car-current-price-box-text">Halbtages Preis</div>
        </div>
        <span></span>
      </div>
    </>
  );
}

export function DisplayCardCurrent(props) {
  return (
    <div style={{color: props.data.color.hex,height:"290px"}} className="current-display-current">
      {props.data.display}
    </div>
  );
}

export function DisplayCardMap(props) {
  return (
    <>
      <div style={{height:"135px"}} className="current-display-status">
      </div>
      <div className="current-display-status-switch">
      </div>
    </>
  );
}


export function DisplayCardChartsHumidity(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="%">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartHumidity />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

export function DisplayCardChartsTemperature(props) {

  return (
    <div className="current-display-chart-container">
      <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={600} height={280} data={props.chartdat}
            margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
          >
            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />

            <XAxis padding={{ left: 30, right: 30 }} dataKey="date" label="" hetopight={40}/>
            <YAxis type="number" unit="°C">
              <Label value="" domain={['auto', 'auto']} position="insideLeft" />
            </YAxis>

            <Tooltip content={<CustomTooltipChartTemperature />} />

            <Line type="monotone" dataKey="data" />
          </LineChart>
        </ResponsiveContainer>
    </div>
  );
}

const CustomTooltipChartTemperature = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} °C</div>
      </div>
    );
  }

  return null;
};

const CustomTooltipChartHumidity = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-chart">
        <div className="custom-tooltip-chart-text">{label}</div>
        <div className="custom-tooltip-chart-text">{payload[0].value} %</div>
      </div>
    );
  }

  return null;
};

class DisplayCardSettings extends Component {
  constructor(props)
  {
    super(props);

    this.state = props.displayData;
  }

  render() {
    return (
      <div style={{maxWidth:'360px', height:'270px', margin:'auto', marginTop:'67px'}}>
        <div className="current-display-settings">
          <div className="current-display-settings-title">Farbe</div>
          <div className="current-display-settings-color">
            <CirclePicker className={this.state.color.hex === '#ffffff' ? 'white-selected' : ''} onChangeComplete={(event) => { this.props.updateCarColor(event) }} value={this.state.color.hex} color={this.state.color.hex} colors={["#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#fff"]}>
            </CirclePicker>
          </div>
        </div>
        <div style={{height:'50px'}} className="current-display-settings">
          <div className="current-display-settings-title">Helligkeit</div>
          <div className="current-display-settings-slider">
            <Slider value={this.state.brightness} defaultValue={60}
                    step={5}
                    marks
                    min={10}
                    max={255}
                    onChangeCommitted={(event,data) => { this.props.updateCarBrightness(data) }}
              />
          </div>
        </div>
      </div>
    );
  }
}


class DisplayCardLocation extends Component {
  render() {
    return (
      <div className="map-display">
          <Map style={{height:'100%'}} defaultCenter={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} defaultZoom={15}>
            <Marker width={30} anchor={[parseFloat(this.props.location.lat), parseFloat(this.props.location.long)]} /*onClick={() => setHue(hue + 20)} */>
             <div style={{color: this.props.displayData.color.hex}} class="panel-display-box">
              {(this.props.displayData.enabled ? this.props.displayData.display : "")}
             </div>
            </Marker>
          </Map>
      </div>
    );
  }
}


export default CarOpen;
