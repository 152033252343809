import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/Block';
import RestoreIcon from '@material-ui/icons/Restore';
import moment from 'moment-timezone';
import linkState from 'linkstate';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';


const AutodecApi = new API();

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: {
        first_name: '',
        last_name: '',
        username: '',
    		company: '',
    		enabled: '',
    		road: '',
        email: '',
    		plz: '',
    		mwst: '',
    		location: '',
      },
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers()
  {
    AutodecApi.endpoints.users.getAll()
      .then((usersGet) => {
        this.setState({ user:  usersGet.data.results[0] });
        console.log(usersGet.data.results[0]);
        this.setState({ loading: false });
      });
  }

  updateUsers(data)
  {
    this.setState({ loading: true });

    AutodecApi.endpoints.users.patch({ id: this.state.user.id},
      {
        first_name: this.state.user.first_name,
        last_name: this.state.user.last_name,
        username: this.state.user.username,
        company: this.state.user.company,
        enabled: this.state.user.enabled,
        road: this.state.user.road,
        email: this.state.user.email,
        plz: this.state.user.plz,
        mwst: this.state.user.mwst,
        location: this.state.user.location,
      })
      .then((rentalsGet) => {
        if(rentalsGet)
        {
          this.setState({ loading: false });
        }
      });
  }

  render() {
    return (
      <SecuredZone title="Einstellungen"
        breadcrumbs={{ '/': 'Dashboard', '/settings':'Einstellungen' , '/settings/company-data':'Firmen-Daten'}}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Aktualisieren': this.updateUsers.bind(this)}}
      >
        <div className="settings-title">Firmen-Daten</div>
          <div className="settings-compyn-data-input">
            <TextField
              autoFocus
              margin="dense"
              id="first_name"
              label="Vorname"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.first_name}
              onInput={linkState(this, 'user.first_name')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="last_name"
              label="Nachname"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.last_name}
              onInput={linkState(this, 'user.last_name')}
            />
          </div>
          <div className="settings-compyn-data-input">
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="E-Mail Adresse"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.email}
              onInput={linkState(this, 'user.email')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="company"
              label="Firmenname"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.company}
              onInput={linkState(this, 'user.company')}
            />
          </div>
          <div className="settings-compyn-data-input">
            <TextField
              autoFocus
              margin="dense"
              id="road"
              label="Strasse"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.road}
              onInput={linkState(this, 'user.road')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="plz"
              label="PLZ"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.plz}
              onInput={linkState(this, 'user.plz')}
            />
          </div>
          <div className="settings-compyn-data-input">
            <TextField
              autoFocus
              margin="dense"
              id="location"
              label="Standort"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.location}
              onInput={linkState(this, 'user.location')}
            />
            <span></span>
            <TextField
              autoFocus
              margin="dense"
              id="mwst"
              label="MWST-Nummer"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.user.mwst}
              onInput={linkState(this, 'user.mwst')}
            />
          </div>

      </SecuredZone>
    );
  }
}

export default Settings;
