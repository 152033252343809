import React from 'react';
//import { Link } from "react-router-dom";
import '../css/card.css';

export default function Card(props) {
  return (
    <div className={`card-container card-${props.width}`}>
      <div className="card-container-title">
        <div className="card-container-text">
          {props.title}
        </div>
        <div className="card-container-buttons">
          { props.add ? props.add : '' }
        </div>
        <span></span>
      </div>
      {props.data}
    </div>
  );
}
