import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './rental-leases-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6

const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class RentalLeasesDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      rentalLease: {
        unikey: '',
        title: '',
        enabled: true,
        text: '',
        textKaution: '',
      },
      modules: {
          toolbar: {
            container: [
              [{ 'header': [1, 2, 3, false] }],
              ['bold', 'italic', 'underline','strike', 'blockquote'],
              [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
              [{ 'align': [] }],
              ['link'],
              ['clean'],
             ],
          },
        },
      loading: true,
    };

    if(props.rentalLease)
    {
      this.isAddMode = false;
      this.state.rentalLease = props.rentalLease;
    }
    else
    {
      this.state.rentalLease.unikey = uuid();
    }
  }

  updateText(data)
  { // eslint-disable-next-line
    this.state.rentalLease.text = data;
    this.setState({ update: true });
  }

  updateTextKaution(data)
  { // eslint-disable-next-line
    this.state.rentalLease.textKaution = data;
    this.setState({ update: true });
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Mietvertrag { this.isAddMode ? "Hinzufügen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-renal-lease-name">
            <TextField
              autoFocus
              margin="dense"
              id="title"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              value={this.state.rentalLease.title}
              onInput={linkState(this, 'rentalLease.title')}
            />
          </div>
          <div className="p-line-two">
            <TextField
              margin="dense"
              id="rentalLease.countHaft"
              label="Haftplichtversicherung"
              type="number"
              fullWidth
              variant="outlined"
              value={this.state.rentalLease.countHaft}
              onInput={linkState(this, 'rentalLease.countHaft')}
            />
            <TextField
              margin="dense"
              id="rentalLease.countKasko"
              label="Kaskoversicherung"
              type="number"
              fullWidth
              variant="outlined"
              value={this.state.rentalLease.countKasko}
              onInput={linkState(this, 'rentalLease.countKasko')}
            />
          </div>
          <div className="pt-line-renal-lease-two">
            <div class="rental-lease-dialog-title">Allgemeine Geschäftsbedingungen</div>
            <ReactQuill className="rental-leases-file" value={this.state.rentalLease.text} modules={this.state.modules} style={{ backgroundColor: 'white' }} onChange={this.updateText.bind(this)} />
          </div>
          <div className="pt-line-renal-lease-five">
            <div class="rental-lease-dialog-title">Kautionsbedigungen</div>
            <ReactQuill className="rental-leases-five" value={this.state.rentalLease.textKaution} modules={this.state.modules} style={{ backgroundColor: 'white' }} onChange={this.updateTextKaution.bind(this)} />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default RentalLeasesDialog;
