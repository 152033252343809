import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

export default theme;
