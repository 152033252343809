import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import linkState from 'linkstate';
import './panel-flow-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputMask from "react-input-mask";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class PanelFlowDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      flow: {
        id: '',
        t: 1,
        v: '',
        c: 10,
        p: 1,
        startDisplay: '',
        endDisplay: ''
      },
      flowEditIndex: 0,
      id: 0,
      isAddMode: true,
    };

    if(props.flowData)
    {
      this.isAddMode = false;
      this.state.flow = props.flowData;
      this.state.isAddMode = false;
    }
    else
    {
      this.state.flow.id = uuid();
    }
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  changeSelect = (e) => {
    const { value } = e.target;
    this.setState({ flow: { ...this.state.flow, t: parseInt(value)} });
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Flow { this.isAddMode ? "Erstellen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="flow-line-one">
            <InputMask mask="mt"
              value={this.state.flow.c}
              onInput={linkState(this, 'flow.c')}
              formatChars={{"t": "[0-9]","m": "[0-5]"}}
              maskChar={null}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  required
                  margin="dense"
                  id="c"
                  label="Anzeige Zeit"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            </InputMask>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.flow.p}
                  onChange={linkState(this, 'flow.p')}
                  name="p"
                  color="primary"
                />
              }
              label="Zentrieren"
            />
          </div>
          <div className="flow-line-time">
            <InputMask mask="ht:mt"
              value={this.state.flow.startDisplay}
              onInput={linkState(this, 'flow.startDisplay')}
              formatChars={{"h": "[0-2]", "t": "[0-9]", "m": "[0-6]"}}
              maskChar={null}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  required
                  margin="dense"
                  id="startDisplay"
                  label="Von"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            </InputMask>
            <InputMask mask="ht:mt"
              value={this.state.flow.endDisplay}
              onInput={linkState(this, 'flow.endDisplay')}
              formatChars={{"h": "[0-2]", "t": "[0-9]", "m": "[0-6]"}}
              maskChar={null}
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  required
                  margin="dense"
                  id="endDisplay"
                  label="Bis"
                  type="text"
                  fullWidth
                  variant="outlined"
                />
              )}
            </InputMask>
          </div>
          <div className="flow-line-two">
            <NativeSelect onChange={this.changeSelect} id="select">
              <option value="1">Text</option>
              <option value="2">Zeit</option>
              <option value="3">Temperatur</option>
            </NativeSelect>
          </div>
            <div className="flow-line-four">
              { (this.state.flow.t === 1) ?
                <>
                  <span></span>
                  <TextField
                    required
                    margin="dense"
                    id="v"
                    label="Text"
                    type="text"
                    fullWidth
                    variant="outlined"
                    value={this.state.flow.v}
                    onInput={linkState(this, 'flow.v')} />
                    <span></span>
                </>
                :
                <>
                  <span></span>
                </>
              }
            </div>
        </DialogContent>
        <DialogActions>
          <div className="flow-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              { this.isAddMode ? "Erstellen" : "Speichern" }
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}


export default PanelFlowDialog;
