import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {v4 as uuid} from "uuid";
import { withStyles } from '@material-ui/core/styles';
import API from '../../../api/api';
import linkState from 'linkstate';
import './rental-dialog.css';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';


const AutodecApi = new API();

const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),

    fontFamily: 'Helvetica',
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

class RentalDialog extends Component {

  constructor(props) {
    super(props);

    this.isAddMode = true;

    this.state = {
      rental: {
        unikey: '',
        enabled: true,
        car: 0,
        customer: 0
      },
      cars:[],
      customers:[],
      loading: true,
    };

    if(props.rental)
    {
      this.isAddMode = false;
      this.state.rental = props.rental;
    }
    else
    {
      this.state.rental.unikey = uuid();
    }
  }

  componentDidMount() {
    this.getCars();
    this.getCustomers();
  }

  getCustomers()
  {
    this.setState({ loading: true });
    AutodecApi.endpoints.customers.getAll()
      .then((rentalGet) => {
        try
        {
          this.setState({ customers:  rentalGet.data.results });
          this.setState({ loading: false });
          this.state.rental.customer = rentalGet.data.results[0].id;
        }
        catch(e)
        {

        }
      });
  }

  getCars()
  {
    this.setState({ loading: true });
    AutodecApi.endpoints.cars.getAll()
      .then((rentalGet) => {
        try
        {
          this.setState({ cars:  rentalGet.data.results });
          this.setState({ loading: false });
          this.state.rental.car = rentalGet.data.results[0].id;
        }
        catch(e)
        {

        }
      });
  }

  render() {
    return (
      <div>
        <DialogTitle id="form-dialog-title">Vermietung { this.isAddMode ? "Hinzufügen" : "Bearbeiten" }</DialogTitle>
        { this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        <DialogContent>
          <div className="pt-line-two">
            <FormControl>
              <InputLabel htmlFor="demo-customized-select-native">Fahrzeug</InputLabel>
              <NativeSelect
                disableAutoFocusItem
                id="panel"
                label="Fahrzeug"
                value={this.state.rental.car}
                onChange={linkState(this, 'rental.car')}
                input={<BootstrapInput />}
              >
                {this.state.cars.map((row) => (
                    <option key={row.id} value={row.id}>{row.make} {row.model} / {row.plate}</option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </div>
          <div className="pt-line-two">
            <FormControl>
              <InputLabel htmlFor="demo-customized-select-native">Kunde</InputLabel>
              <NativeSelect
                disableAutoFocusItem
                id="panel"
                label="Kunde"
                value={this.state.rental.customer}
                onChange={linkState(this, 'rental.customer')}
                input={<BootstrapInput />}
              >
                {this.state.customers.map((row) => (
                    <option key={row.id} value={row.id}>{row.firstName} {row.secondName}</option>
                  )
                )}
              </NativeSelect>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="p-line-end">
            <Button variant="contained" onClick={this.props.close}>
              Abbrechen
            </Button>
            <span></span>
            <Button variant="contained" color="primary" onClick={() => { this.props.submit(this.state) }}>
              Speichern
            </Button>
          </div>
        </DialogActions>
      </div>
    );
  }
}

export default RentalDialog;
