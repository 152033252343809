import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import '../../css/main.css';
import API from '../../api/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Component } from 'react';
import SecuredZone from '../../components/SecuredZone';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CarDialog from '../../components/dialogs/car-dialog/car-dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment-timezone';
import { Link } from "react-router-dom";

const AutodecApi = new API();

class Cars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      dialogCar: false,
      cars: {}
    };
  }

  componentDidMount() {
    this.getCars();
  }

  getCars()
  {
    AutodecApi.endpoints.carsView.getAll()
      .then((carsGet) => {
        this.setState({ cars:  carsGet.data.results });
        this.setState({ loading: false });
      });
  }

  closeCar()
  {
    this.setState({ dialogCar:  false });
  }

  openCar() {
    this.setState({ dialogCar:  true });
  }

/*  deletePanelToken(panelToken) {
    console.log(panelToken);
    this.setState({ loading: true });
    AutodecApi.endpoints.panelsToken.delete( { id: panelToken } )
      .then(() => {
        this.setState({ loading: false });
        this.getCars();
      });
  }*/

  submitCar(data)
  {
    this.closeCar();
    this.setState({ loading: true });
    if(data.isAddMode)
    {
      this.state.cars.push(data.car)
    }

    AutodecApi.endpoints.cars.create(data.car)
      .then(() => {
        this.setState({ loading: false });
        this.getCars();
      });
  }

  render() {
    return (
      <SecuredZone title="Fahrzeuge"
        breadcrumbs={{ '/': 'Dashboard', '/cars':'Fahrzeuge' }}
        loading={ this.state.loading ? <LinearProgress color="primary" /> : <span className="loader-span"></span> }
        menu={{ 'Fahrzeug Hinzufügen': this.openCar.bind(this)}}
      >
        <Dialog open={this.state.dialogCar} onClose={this.closeCar.bind(this)}>
          <CarDialog close={this.closeCar.bind(this)} submit={this.submitCar.bind(this)}/>
        </Dialog>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"

        >
        { Array.isArray(this.state.cars) ?
          <>
          {this.state.cars.map(function(car){
            return (
              <Card key={car.unikey} className="panel-card">
                <CardActionArea component={Link} to={`/cars/${car.id}/`}>
                  <CardMedia height="140">
                    {( car.carImage ? 
                        <img className="card-image" src={car.carImage.image['400']}  alt="Logo" />
                        :
                        ''
                    )}
                  </CardMedia>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {car.make} {car.model}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            )
           })}
           </>
           :
           <>
           </>
         }
        </Grid>
      </SecuredZone>
    );
  }
}

export default Cars;
